/**
 * Created by vladislav on 29.10.2020
 */

var BaseBlock = function (x, y, keypad) {
    cleverapps.EventEmitter.call(this);

    this.x = x;
    this.y = y;

    this.keypad = keypad;
    this.game = keypad.game;
};

BaseBlock.prototype = Object.create(cleverapps.EventEmitter.prototype);
BaseBlock.prototype.constructor = BaseBlock;

BaseBlock.prototype.move = function (x, y, options) {
    options = options || {};
    var duration = this.getMoveDuration(this.x, this.y, x, y);
    if (options.updateKeypad) {
        this.keypad.field[this.x][this.y] = undefined;
        this.keypad.field[x][y] = this;
    }
    this.x = x;
    this.y = y;
    if (!options.silent) {
        this.trigger('move', duration);
    }
    return duration;
};

BaseBlock.prototype.lose = function () {
    this.trigger('lose');
};

BaseBlock.prototype.getMoveDuration = function (xFrom, yFrom, xTo, yTo) {
    var minDuration = 0.15;
    return Math.max(minDuration, Math.sqrt((xFrom - xTo) * (xFrom - xTo) + (yFrom - yTo) * (yFrom - yTo)) * 0.05);
};

BaseBlock.prototype.getLoseDuration = function () {
    return 0.5;
};

BaseBlock.prototype.getShowUpDuration = function () {
    return 0.5;
};

BaseBlock.prototype.getViewClass = function () {

};

BaseBlock.prototype.showUp = function (silent) {
    this.trigger('showUp', silent);
};

BaseBlock.prototype.remove = function (silent) {
    this.removed = true;

    if (!silent) {
        this.trigger('remove');
    }
};