/**
 * Created by vladislav on 29.10.2020
 */

var CoinBagBlockView = BaseBlockView.extend({
    ctor: function (block) {
        this._super(block);

        var styles = cleverapps.styles.CoinBagBlockView;

        this.sound = bundles.game.urls.coins_bonus_effect;

        this.animation = new cleverapps.Spine(bundles.game.jsons.keypad_bag_coin_json);
        this.addChild(this.animation);
        this.animation.setPositionRound(this.width / 2, this.height / 2 + styles.animation.offsetY);
        this.animation.setAnimation(0, "idle", true);

        this.setLocalZOrder(this.block.y + 100);

        this.block.on("showUp", this.createListener(this.showUp.bind(this)));
    },

    showUp: function (silent) {
        this._super(silent);

        this.animation.setAnimation(0, "appearance", false);

        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.animation.setAnimation(0, "idle", true);
        }.bind(this));
    }
});

cleverapps.styles.CoinBagBlockView = {
    animation: {
        offsetY: -23
    },

    rewardView: {
        offsetX: -10,
        offsetY: 5,
        coins: [
            {
                radiusX: 30,
                radiusY: 30,
                count: 5,
                scale: 0.4
            }
        ]
    }
};