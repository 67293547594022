/**
 * Created by vladislav on 18.03.2021
 */

var SlotMachine = function (tickets) {
    cleverapps.EventEmitter.call(this);

    this.reels = [];
    for (var i = 0; i < SlotMachine.REELS_AMOUNT; i++) {
        this.reels.push(new SlotMachineReel());
    }

    this.tickets = tickets;
    this.onChangeTickets = function () {};
};

SlotMachine.prototype = Object.create(cleverapps.EventEmitter.prototype);
SlotMachine.prototype.constructor = SlotMachine;

SlotMachine.prototype.isForceAvailable = function () {
    return !cleverapps.forces.isShown(Forces.SLOT_MACHINE.id);
};

SlotMachine.prototype.isHelpForceAvailable = function () {
    return !cleverapps.forces.isShown(Forces.SLOT_MACHINE_HELP.id);
};

SlotMachine.prototype.showForce = function () {
    if (this.getTickets() < SlotMachine.TICKETS_PER_SPIN) {
        this.setTickets(SlotMachine.TICKETS_PER_SPIN);
    }

    this.trigger("showForce");
};

SlotMachine.prototype.showHelpForce = function () {
    this.trigger("showHelpForce");
};

SlotMachine.prototype.getTickets = function () {
    return this.tickets;
};

SlotMachine.prototype.setTickets = function (tickets, silent) {
    this.tickets = tickets;
    this.onChangeTickets(silent);

    if (!silent) {
        this.trigger("updateTickets");
    }
};

SlotMachine.prototype.canSpin = function () {
    return this.getTickets() >= SlotMachine.TICKETS_PER_SPIN;
};

SlotMachine.prototype.spin = function (f, isForce) {
    this.setTickets(this.tickets - SlotMachine.TICKETS_PER_SPIN);

    var payline = this.calcPayline(isForce);

    this.trigger("spin");

    cleverapps.meta.compound(f, [
        function (f1) {
            f1 = cleverapps.wait(this.reels.length, f1);

            this.reels.forEach(function (reel, index) {
                setTimeout(function () {
                    this.reels[index].spin(payline[index], f1);
                }.bind(this), index * SlotMachine.REEL_SPIN_DELAY);
            }, this);
        }.bind(this),

        function (f1) {
            setTimeout(f1, 500);
        },

        this.giveReward.bind(this)
    ]);
};

SlotMachine.prototype.calcPayline = function (isForce) {
    if (cleverapps.config.debugMode && SlotMachine.PREDEFINED_PAYLINE) {
        return SlotMachine.PREDEFINED_PAYLINE;
    }

    if (isForce) {
        return [SlotMachine.BOOSTER_1_SYMBOL.type, SlotMachine.BOOSTER_1_SYMBOL.type, SlotMachine.BOOSTER_3_SYMBOL.type];
    }

    var payline = [];
    for (var i = 0; i < SlotMachine.REELS_AMOUNT; i++) {
        payline.push(cleverapps.Random.choose(SlotMachineReel.SYMBOLS).type);
    }

    return payline;
};

SlotMachine.prototype.giveReward = function (f) {
    var payline = this.reels.map(function (reel) {
        return reel.getCurrent(); 
    });

    var prizeToGive;
    var toHighlight = [];
    for (var i = 0; i < SlotMachine.PRIZES_TABLE.length; i++) {
        var toHighlightLocal = [];
        var prize = SlotMachine.PRIZES_TABLE[i];

        var requiredTypes = {};
        for (var j = 0; j < prize.payline.length; j++) {
            if (!requiredTypes[prize.payline[j]]) {
                requiredTypes[prize.payline[j]] = 0;
            }

            requiredTypes[prize.payline[j]]++;
        }

        for (j = 0; j < payline.length; j++) {
            if (requiredTypes[payline[j].type] === undefined) {
                toHighlightLocal.push(false);
            } else {
                requiredTypes[payline[j].type]--;
                if (requiredTypes[payline[j].type] >= 0) {
                    toHighlightLocal.push(true);
                }
            }
        }

        var found = true;
        for (var type in requiredTypes) {
            if (requiredTypes[type] > 0) {
                found = false;
                break;
            }
        }

        if (found) {
            prizeToGive = prize;
            toHighlight = toHighlightLocal;
        }
    }

    for (i = 0; i < this.reels.length; i++) {
        this.reels[i].getCurrent().animate(toHighlight[i]);
    }

    this.trigger("giveReward", prizeToGive, f);

    if (prizeToGive) {
        setTimeout(function () {
            var reward = new RewardsList(prizeToGive.reward, { event: cleverapps.EVENTS.EARN.SLOTMACHINE });
            reward.receiveRewards();
            reward.onAnimationFinished();
        }, 2000);
    }
};

SlotMachine.REELS_AMOUNT = 3;
SlotMachine.REEL_SPIN_DELAY = 300;
SlotMachine.TICKETS_PRICE = 249;
SlotMachine.TICKETS_BUY_AMOUNT = 25;
SlotMachine.TICKETS_PER_SPIN = 5;

SlotMachine.COIN_SYMBOL = {
    type: "coin",
    icon: bundles.slot_machine.frames.coin_png
};

SlotMachine.COINS_SYMBOL = {
    type: "coins",
    icon: bundles.slot_machine.frames.coins_png
};

SlotMachine.BOOSTER_1_SYMBOL = {
    type: "booster_1",
    icon: bundles.slot_machine.frames.booster_13_png
};

SlotMachine.BOOSTER_2_SYMBOL = {
    type: "booster_2",
    icon: bundles.slot_machine.frames.booster_14_png
};

SlotMachine.BOOSTER_3_SYMBOL = {
    type: "booster_3",
    icon: bundles.slot_machine.frames.booster_15_png
};

SlotMachine.SYMBOLS = {};
SlotMachine.SYMBOLS[SlotMachine.COIN_SYMBOL.type] = SlotMachine.COIN_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.COINS_SYMBOL.type] = SlotMachine.COINS_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.BOOSTER_1_SYMBOL.type] = SlotMachine.BOOSTER_1_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.BOOSTER_2_SYMBOL.type] = SlotMachine.BOOSTER_2_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.BOOSTER_3_SYMBOL.type] = SlotMachine.BOOSTER_3_SYMBOL;

SlotMachine.PRIZES_TABLE = [
    {
        payline: ["booster_1", "booster_1"],
        reward: {
            boosters: {
                13: 3
            }
        }
    },
    {
        payline: ["booster_1", "booster_1", "booster_1"],
        reward: {
            boosters: {
                13: 5
            }
        }
    },
    {
        payline: ["booster_2", "booster_2"],
        reward: {
            boosters: {
                14: 3
            }
        }
    },
    {
        payline: ["booster_2", "booster_2", "booster_2"],
        reward: {
            boosters: {
                14: 5
            }
        }
    },
    {
        payline: ["booster_3", "booster_3"],
        reward: {
            boosters: {
                15: 3
            }
        }
    },
    {
        payline: ["booster_3", "booster_3", "booster_3"],
        reward: {
            boosters: {
                15: 5
            }
        }
    },
    {
        payline: ["coin", "coin", "coin"],
        reward: {
            hard: 250
        }
    },
    {
        payline: ["coins", "coins", "coins"],
        reward: {
            hard: 500
        }
    }
];