/**
 * Created by vladislav on 29.10.2020
 */

var CoinBlockView = BaseBlockView.extend({
    ctor: function (block) {
        this._super(block);

        var styles = cleverapps.styles.CoinBlockView;

        this.sound = bundles.game.urls.coins_bonus_effect;

        this.animation = new cleverapps.Spine(bundles.game.jsons.keypad_coin_json);
        this.addChild(this.animation);
        this.animation.setPositionRound(this.width / 2, this.height / 2 + styles.animation.offsetY);
        this.animation.setAnimation(0, "idle", true);

        this.setLocalZOrder(this.block.y + 100);

        this.block.on("showUp", this.createListener(this.showUp.bind(this)));
    },

    showUp: function (silent) {
        this._super(silent);

        this.animation.setAnimation(0, "showUp", false);

        this.animation.setCompleteListener(function () {
            this.animation.setAnimation(0, "showUpFinish", false);
            this.animation.setCompleteListener(function () {
                this.animation.setAnimation(0, "idle", true);
            }.bind(this));
        }.bind(this));
    }
});

cleverapps.styles.CoinBlockView = {
    animation: {
        offsetY: 5
    }
};