/**
 * Created by vladislav on 18.03.2021
 */

var SlotMachineScene = cleverapps.FixedWidthScene.extend({
    ctor: function (mission) {
        this._super();
        this.slotMachine = mission.logic;
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_SLOT_MACHINE);

        cleverapps.Random.randomSeed();

        this.slotMachineContainer = new cc.Node();
        this.slotMachineContainer.addChild(new SlotMachineButton(this.slotMachine.slotMachine));
        this.slotMachineContainer.addChild(new SlotMachineView(this.slotMachine.slotMachine));
        this.addChild(this.slotMachineContainer);

        this.updateContainerPosition();
    },

    updateContainerPosition: function () {
        this.slotMachineContainer.setScale(this.background.scale);
        this.slotMachineContainer.setPositionRound(this.convertToNodeSpace(this.background.convertToWorldSpace(cc.p(this.background.width / 2, this.background.height / 2))));
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this._super();
        this.updateContainerPosition();
    },

    onExit: function () {
        this._super();

        this.slotMachine.slotMachine.purge();
    },

    getBackgroundStyles: function () {
        return {
            bundle: "slot_machine_bg",
            backgroundId: "background"
        };
    },

    getAudioStyles: function () {
        return {
            res: bundles.slot_machine.urls.music,
            delay: 1.0
        };
    },

    listBundles: function () {
        return ["slot_machine", "slot_machine_bg"];
    }
});
