/**
 * Created by vladislav on 06.07.20.
 */

var LetterBlockView = BaseBlockView.extend({
    ctor: function (block) {
        this._super(block);

        this.charView = CharView.Create(block.symbol, {
            noBg: true
        });
        this.addChild(this.charView);
        this.charView.setPositionRound(this.width / 2, this.height / 2);

        var styles = cleverapps.styles.LetterBlockView;

        this.bg = new cc.Sprite(this.getBgImage());
        this.addChild(this.bg);
        this.bg.setLocalZOrder(-1);
        this.bg.setPositionRound(this.width / 2 + styles.bg.offset.x, this.height / 2 + styles.bg.offset.y);

        this.baseScale = this.scale;

        this.updateHint({ silent: true });

        if (this.block.magnifier) {
            this.addMagnifier();
        }

        this.block.on("unhighlight", this.unhighlight.bind(this), this);
        this.block.on("highlight", this.highlight.bind(this), this);
        this.block.on("wrong", this.wrong.bind(this), this);
        this.block.on("board", this.board.bind(this), this);
        this.block.on("setHint", this.updateHint.bind(this), this);
        this.block.on("addMagnifier", this.addMagnifier.bind(this), this);
        this.block.on("showUp", this.showUp.bind(this), this);
    },

    board: function () {
        this.bg.setSpriteFrame(this.getBgImageHighlighted());
        this.bg.setColor(this.getBgColorHighlighted());
    },

    wrong: function (direction) {
        var styles = cleverapps.styles.LetterBlockView;
        var dx = direction.col !== 0 ? styles.wrong.dt : 0;
        var dy = direction.row !== 0 ? styles.wrong.dt : 0;
        var pos = BaseBlockView.alignInTheGrid(this.block.x, this.block.y);
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.05, pos.x + dx, pos.y + dy).easing(cc.easeOut(2)),
            new cc.MoveTo(0.05, pos.x - dx, pos.y - dy).easing(cc.easeOut(2)),
            new cc.MoveTo(0.05, pos.x + dx / 2, pos.y + dy / 2).easing(cc.easeOut(2)),
            new cc.MoveTo(0.05, pos.x, pos.y).easing(cc.easeOut(2))
        ));
    },

    addMagnifier: function () {
        this.magnifier = new MagnifierView(this.block.magnifier);
        this.addChild(this.magnifier);
    },

    updateHint: function (options) {
        options = options || {};

        var updateBg = function () {
            if (this.block.removed) {
                return;
            }
            this.bg.setSpriteFrame(this.getBgImage());
            this.bg.setColor(this.getBgColor());
        }.bind(this);

        var delay = options.hintAnimationDuration || 0;
        if (delay === 0) {
            updateBg();
        } else {
            this.bg.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(updateBg)
            ));
        }

        if (this.block.hint && !options.silent) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    this.runScaleAnimation();
                }.bind(this))
            ));

            if (options.hintAnimationDuration) {
                this.addHintAnimation(options.hintAnimationDuration, options.startPos);
            }
        }
    },

    addHintAnimation: function (duration, startPosition) {
        cleverapps.UI.animateHintFly(this, {
            duration: duration,
            startPosition: startPosition
        });
    },

    runScaleAnimation: function () {
        this.setScale(this.baseScale);
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, this.baseScale * 1.2),
            new cc.ScaleTo(0.1, this.baseScale)
        ));
    },

    getBgColor: function () {
        if (!this.block.hint || !LetterBlockView.hintColor) {
            return cleverapps.styles.COLORS.WHITE;
        }

        return LetterBlockView.hintColor;
    },

    getBgColorHighlighted: function () {
        return cleverapps.styles.COLORS.WHITE;
    },

    getBgImage: function () {
        if (this.block.hint) {
            return LetterBlockView.bgImageHint || bundles.game.frames.letter_keypad_bg_plain_png;
        }

        return LetterBlockView.bgImage;
    },

    getBgImageHighlighted: function () {
        return LetterBlockView.bgImageHighlighted;
    },

    highlight: function () {
        this.bg.setSpriteFrame(this.getBgImageHighlighted());
        this.bg.setColor(this.getBgColorHighlighted());

        this.runScaleAnimation();
    },

    unhighlight: function () {
        this.bg.setSpriteFrame(this.getBgImage());
        this.bg.setColor(this.getBgColor());
    }
});

cleverapps.styles.LetterBlockView = {
    wrong: {
        dt: 8
    },
    bg: {
        offset: {
            x: 0,
            y: 3
        }
    },
    hint: {
        start: {
            dx: 0,
            dy: 30
        }
    }
};