/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["simpleForce"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 9,

    filter: function () {
        return cleverapps.simple.isForceAvailable();
    },

    action: function () {
        cleverapps.meta.display({
            focus: "simpleForce",
            control: ["progress_view", "play_button"],
            actions: [
                function (f) {
                    if (cleverapps.simple.pendingsStars > 0) {
                        cleverapps.simple.updateProgress(f);
                    } else {
                        cleverapps.timeouts.setTimeout(f, 500);
                    }
                },

                function (f) {
                    cleverapps.simple.showForce(f);
                },

                function (f) {
                    if (cleverapps.playButton && cleverapps.playButton.passedLevel) {
                        cleverapps.playButton.animatePassLevel();
                    }
                    f();
                }
            ]
        });
    }
};

Placements.ENTRIES["simpleUpdateProgress"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: -2,

    filter: function () {
        return cleverapps.simple.pendingsStars > 0 && !cleverapps.simple.canMoveNext();
    },

    action: function () {
        cleverapps.simple.updateProgress();
    }
};

Placements.ENTRIES["simpleMoveNext"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 10,

    filter: function () {
        return cleverapps.simple.canMoveNext();
    },

    action: function () {
        cleverapps.meta.display({
            focus: "simpleMoveNext",
            control: ["progress_view"],
            actions: [
                function (f) {
                    cleverapps.simple.loadMoveNextBundles();

                    if (cleverapps.simple.pendingsStars > 0) {
                        cleverapps.simple.updateProgress(f);
                    } else {
                        f();
                    }
                },

                function (f) {
                    cleverapps.simple.moveNext(f);
                },

                function (f) {
                    if (cleverapps.playButton && cleverapps.playButton.passedLevel && cleverapps.config.name !== "woodenblock") {
                        cleverapps.playButton.animatePassLevel();
                    }
                    f();
                }
            ]
        });
    }
};