/**
 * Created by vladislav on 22.03.2021
 */

var SlotMachineSymbol = function (reel, data, index) {
    cleverapps.EventEmitter.call(this);

    Object.assign(this, data);

    this.reel = reel;

    this.index = index;
};

SlotMachineSymbol.prototype = Object.create(cleverapps.EventEmitter.prototype);
SlotMachineSymbol.prototype.constructor = SlotMachineSymbol;

SlotMachineSymbol.prototype.animate = function (highlight) {
    this.trigger("animate", highlight);
};

SlotMachineSymbol.prototype.reset = function () {
    this.trigger("reset");
};

SlotMachineSymbol.prototype.move = function () {
    this.trigger("move");
};

SlotMachineSymbol.prototype.spin = function (isLast) {
    this.trigger("spin", isLast);
};