/**
 * Created by vladislav on 17.09.2020
 */

var StarsProgressBar = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.options = options;

        this.stars = this.options.stars !== undefined ? this.options.stars : cleverapps.simple.stars;
        this.current = this.options.current !== undefined ? this.options.current : cleverapps.simple.current;

        var styles = cleverapps.styles.StarsProgressBar;

        this.setAnchorPoint(0.5, 0.5);

        this.setContentSize2(this.options.width, styles.bar.height);

        this.addProgressBar();

        if (this.options.withPresent) {
            this.addPresent();
        }

        cleverapps.simple.on("changeStars", function () {
            this.setStars(cleverapps.simple.pendingsStars > 0 ? (cleverapps.simple.stars - cleverapps.simple.pendingsStars) : cleverapps.simple.stars);
        }.bind(this));

        this.setStars(this.stars);
    },

    addProgressBar: function () {
        var styles = cleverapps.styles.StarsProgressBar.bar;

        var progressBar = this.bar = new ScaledProgressBar({
            background: bundles.progress_bar.frames.bg_simple,
            progress: bundles.progress_bar.frames.bar_simple,
            barText: {
                font: cleverapps.styles.FONTS.STARS_PROGRESS_BAR_TEXT,
                text: "[[%from%/%to%",
                icons: {
                    "[[": bundles.simple.frames.star_png
                },
                dy: styles.dy
            }
        });
        progressBar.setLength(this.options.width + 2 * styles.offsetX);
        progressBar.setPercentage(0);
        progressBar.setPositionRound(this.width / 2, this.height / 2);

        this.addChild(this.bar);
        progressBar.background.setLocalZOrder(-10);
    },

    addPresent: function () {
        var styles = cleverapps.styles.StarsProgressBar;

        this.present = new cleverapps.Spine(bundles.simple.jsons.present_json);
        this.addChild(this.present);
        this.present.setScale(styles.present.scale);
        this.present.setSkin(styles.present.skin);
        this.present.setAnimation(0, styles.present.animation, true);
        this.present.setPositionRound(styles.present);
        this.present.setLocalZOrder(10);

        cleverapps.tooltipManager.create(this.present, {
            text: "OpenBackgroundTooltip",
            rewards: cleverapps.simple.calcReward(),
            location: cleverapps.UI.Tooltip.LOCATION_BELOW,
            size: cleverapps.styles.UI.Tooltip.SIZE.medium
        });
    },

    setStars: function (stars) {
        this.stars = stars;

        this.bar.setGoal(cleverapps.simple.getRequiredStars(this.current));
        this.bar.setPercentage(stars);
    },

    updateProgress: function (addedStars) {
        this.stars += addedStars;

        this.bar.setGoal(cleverapps.simple.getRequiredStars());
        this.bar.setPercentage(this.stars, {
            animated: true
        });
    },

    receivePrize: function () {
        var rewards = cleverapps.simple.calcReward(true);

        rewards.forEach(function (rewardItem) {
            var reward = new Reward(rewardItem.type, rewardItem.value, { event: cleverapps.EVENTS.EARN.LEVEL_REWARD });
            reward.receiveReward();
            reward.collectRewardsAnimation(this.present, {
                flyingAnimation: Reward.JUMP_COLLECT_ANIMATION
            });
        }.bind(this));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    STARS_PROGRESS_BAR_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.StarsProgressBar = {
    bar: {
        offsetX: 15,
        height: 80,
        dy: 4
    },

    present: {
        scale: 0.7,
        x: { align: "right", dx: 90 },
        y: { align: "center", dy: 5 },
        skin: "green",
        animation: "animation"
    }
};