/**
 * Created by Andrey Popov on 07.06.2021
 */

var CryptexMenuBarItemModel = function (config) {
    MenuBarItem.call(this, config);
};

CryptexMenuBarItemModel.prototype = Object.create(MenuBarItem.prototype);
CryptexMenuBarItemModel.prototype.constructor = CryptexMenuBarItemModel;

CryptexMenuBarItemModel.prototype.customInit = function () {
    Game.currentGame.cryptex.onShowForceListener = function () {
        this.onShowForce(this.config.force);
    }.bind(this);

    Game.currentGame.cryptex.onStop = function () {
        this.onRemove();
    }.bind(this);

    this.updateTimer();
};

CryptexMenuBarItemModel.prototype.timerRefresh = function () {
    if (this.timerTTF !== this.calcTimerTTF()) {
        this.updateTimer();
        return;
    }

    this.onChangeText();
};

CryptexMenuBarItemModel.prototype.updateTimer = function () {
    var timer = this.timer;
    this.stopTimer();

    var timeLeft = Game.currentGame.cryptex.countdown.getTimeLeft();
    if (timeLeft > 0) {
        this.setTimer(new cleverapps.CountDown(timeLeft, {
            onFinish: this.updateTimer.bind(this)
        }));
    }

    this.onUpdateTimer(Boolean(this.timer) === Boolean(timer));
    this.onChangeText();
};