/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.simpleStar = {
    getIcon: function () {
        return bundles.reward_icons.frames.simple_star_png;
    },

    handler: function (value) {
        cleverapps.simple.earnStars(value, true);
        return function () {
            cleverapps.simple.pendingsStars += value;
        };
    }
};
