/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["slotMachineForce"] = {
    type: Placements.FREE_FOCUS_OTHER,

    filter: function () {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_SLOT_MACHINE);
        return cleverapps.environment.isSlotMachineScene() && mission && mission.logic.isForceAvailable();
    },

    action: function () {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_SLOT_MACHINE);
        mission && mission.logic.showForce();
    }
};

Placements.ENTRIES["slotMachineHelpForce"] = {
    priority: -10,
    type: Placements.FREE_FOCUS_OTHER,

    filter: function () {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_SLOT_MACHINE);
        return cleverapps.environment.isSlotMachineScene() && mission && mission.logic.isHelpForceAvailable();
    },

    action: function () {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_SLOT_MACHINE);
        mission && mission.logic.showHelpForce();
    }
};