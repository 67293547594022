/**
 * Created by vladislav on 06.10.2020
 */

var CurrentHighlighter = function (game, save) {
    cleverapps.EventEmitter.call(this);

    this.game = game;
    this.active = save.highlightBooster;
};

CurrentHighlighter.prototype = Object.create(cleverapps.EventEmitter.prototype);
CurrentHighlighter.prototype.constructor = CurrentHighlighter;

CurrentHighlighter.prototype.enable = function () {
    this.active = true;

    this.game.storeSave();
};

CurrentHighlighter.prototype.isActive = function () {
    return this.active;
};

CurrentHighlighter.prototype.update = function () {
    if (!this.isActive()) {
        return;
    }

    this.current = this.getCurrentWords();

    this.trigger("changeCurrent");
};

CurrentHighlighter.prototype.getCurrentWords = function () {
    return this.game.keypad.words
        .filter(function (word) {
            return this.game.keypad.findWordIndexes(word); 
        }.bind(this)).map(function (word) {
            return this.game.board.words.indexOf(word);
        }.bind(this));
};