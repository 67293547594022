/**
 * Created by vladislav on 16.07.2021
 */

var SlotMachineTicketsAmount = cc.Node.extend({
    ctor: function (slotMachine) {
        this.slotMachine = slotMachine;

        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.createAmount();

        this.createIcon();

        this.createButton();

        this.slotMachine.on("updateTickets", this.createListener(this.updateAmount.bind(this)));

        cleverapps.aims.registerTarget("slotMachineTicket", this, {
            flyingUnderShadow: true,
            withoutDelta: true
        });
    },

    createAmount: function () {
        var styles = cleverapps.styles.SlotMachineTicketsAmount;

        var bg = new cc.Scale9Sprite(bundles.slot_machine.frames.amount_bg_png);
        bg.setContentSize2(styles.amount.bg);

        this.amount = cleverapps.UI.generateImageText("x" + this.slotMachine.getTickets(), cleverapps.styles.FONTS.SLOT_MACHINE_TICKETS_AMOUNT_TEXT);
        this.amount.baseScale = this.amount.scale;
        bg.addChild(this.amount);
        this.amount.setPositionRound(styles.amount.text);

        this.addChild(bg);
        bg.setPositionRound(styles.amount);
    },

    createIcon: function () {
        var styles = cleverapps.styles.SlotMachineTicketsAmount;

        var icon = new cc.Sprite(bundles.slot_machine.frames.tickets_buy_png);
        this.addChild(icon);
        icon.setPositionRound(styles.icon);
    },

    createButton: function () {
        var styles = cleverapps.styles.SlotMachineTicketsAmount;

        var button = new cleverapps.UI.Button({
            type: {
                button_png: bundles.slot_machine.frames.plus_png,
                button_on_png: bundles.slot_machine.frames.plus_on_png
            },

            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                cleverapps.meta.display({
                    focus: "SlotMachineBuyWindow",
                    action: function (f) {
                        new SlotMachineBuyWindow();

                        cleverapps.meta.onceNoWindowsListener = f;
                    }
                });
            }
        });
        this.addChild(button);
        button.setPositionRound(styles.button);
    },

    updateAmount: function () {
        this.amount.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, this.amount.baseScale * 1.2),
            new cc.CallFunc(function () {
                this.amount.setString("x" + this.slotMachine.getTickets());
            }, this),
            new cc.ScaleTo(0.2, this.amount.baseScale)
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SLOT_MACHINE_TICKETS_AMOUNT_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.SlotMachineTicketsAmount = {
    amount: {
        bg: {
            width: 170,
            height: 80
        },
        text: {
            x: { align: "center" },
            y: { align: "center" }
        },
        x: { align: "center" },
        y: { align: "center", dy: 0 }
    },

    icon: {
        x: { align: "center", dx: -120 },
        y: { align: "center" }
    },

    button: {
        x: { align: "center", dx: 122 },
        y: { align: "center" }
    }
};