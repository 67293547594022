/**
 * Created by andrey on 24.05.17.
 */

cleverapps.override(cleverapps.Tool.game, {
    win_tournament: function (place) {
        var scene = cleverapps.scenes.getRunningScene();
        var game = scene.game;
        if (!(scene instanceof GameScene) || !game) {
            return;
        }

        if (Game.currentGame && Game.currentGame.tournament) {
            Game.currentGame.competition.calcPlace();
            var bots = Game.currentGame.competition.results.filter(function (result) {
                return result.id !== connector.platform.getUserID(); 
            });

            var processed = [], i;
            for (i = 0; i < place - 1; ++i) {
                bots[i].amount = Game.currentGame.competition.options.algo.maxResult;
                processed.push(bots[i]);
            }

            if (processed.length > 0) {
                for (i = 0; i < processed.length; i++) {
                    Game.currentGame.competition.updatePlayerResult(processed[i].id, processed[i].amount);
                }
                Game.currentGame.competition.save();
            }
        }

        setTimeout(function () {
            game.board.words.forEach(function (word) {
                game.board.foundWordsSet[word] = 1;
            });

            game.counter.trigger();
        }, 50);
    },

    win_2: function () {
        cleverapps.Tool.game.win_tournament(2);
    },

    win_3: function () {
        cleverapps.Tool.game.win_tournament(3);
    },

    win_4: function () {
        cleverapps.Tool.game.win_tournament(4);
    },

    cryptex: {
        enable: function () {
            if (!Game.currentGame.cryptex) {
                Game.currentGame.cryptex = new Cryptex(Game.currentGame);
                cleverapps.scenes.getRunningScene().createCryptex();
            } else if (Game.currentGame.cryptex && Game.currentGame.cryptex.running === false) {
                Game.currentGame.cryptex.running = true;
                Game.currentGame.cryptex.opened = false;
            }
        },
        openAnimation: function () {
            if (Game.currentGame.cryptex) {
                Game.currentGame.cryptex.onOpen();
            }
        },
        attemptAnimation: function () {
            if (Game.currentGame.cryptex) {
                Game.currentGame.cryptex.onAttempt();
            }
        },
        failAnimation: function () {
            if (Game.currentGame.cryptex) {
                Game.currentGame.cryptex.onFail();
            }
        },
        open: function () {
            if (Game.currentGame.cryptex && Game.currentGame.cryptex.running) {
                Game.currentGame.cryptex.open(function () {});
            }
        },
        attempt: function () {
            if (Game.currentGame.cryptex) {
                Game.currentGame.cryptex.attempt();
            }
        },
        fail: function () {
            if (Game.currentGame.cryptex && Game.currentGame.cryptex.running) {
                Game.currentGame.cryptex.timerFinish();
            }
        }
    },

    addCoin: function () {
        if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
            Game.currentGame.keypad.addRandomBonus();
        } else {
            alert("Scramble only");
        }
    },

    openWord: function (words) {
        if (Game.currentGame.counter.isActive()) {
            return;
        }

        words = words || Game.currentGame.board.words.filter(function (word) {
            return !Game.currentGame.board.foundWordsSet[word];
        });

        for (var i = 0; i < words.length; i++) {
            var indexes = Game.currentGame.keypad.findWordIndexes(words[i]);

            if (indexes) {
                indexes.forEach(function (index) {
                    Game.currentGame.current.push(index, true);
                });
                Game.currentGame.keypad.trigger("attempt", indexes);

                return;
            }
        }
    },

    listWords: function () {
        alert(Game.currentGame.level.content.words.join(" "));
    },

    openExtra: function () {
        var words = Object.keys(Game.currentGame.extra.wordsSet).filter(function (word) {
            return !Game.currentGame.extra.foundWordsSet[word] && Game.currentGame.levelContent.words.indexOf(word) === -1;
        });

        cleverapps.Tool.game.openWord(words);
    },

    listExtra: function () {
        var extraWords = [];
        if (cleverapps.config.name === "scramble") {
            var keypad = Game.currentGame.keypad;
            var words = Object.keys(Game.currentGame.extra.wordsSet);
            for (var i = 0; i < words.length && extraWords.length < 30; i++) {
                var indexes = keypad.findWordIndexes(words[i]);
                if (indexes && keypad.words.indexOf(words[i]) === -1) {
                    extraWords.push(words[i]);
                }
            }
        } else {
            extraWords = Game.currentGame.board.levelContent.extra;
        }

        alert(extraWords.join(" "));
    },

    addCookie: function () {
        levels.cookieJar.incCounter();
    },

    almostFillCookie: function () {
        levels.cookieJar.almostFill();
    }
});

cleverapps.Tool.UI.windows.cryptexWindow = function () {
    cleverapps.meta.display({
        focus: "CryptexWindowTool",
        action: function (f) {
            new CryptexWindow();
            cleverapps.meta.onceNoWindowsListener = f;
        }
    });
};

delete cleverapps.Tool.UI.windows.movesFromFriend;