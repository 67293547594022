/**
 * Created by slava on 23.03.17.
 */

cleverapps.BoardView = cc.Node.extend({
    ctor: function (board, options) {
        this._super();

        this.board = board;
        this.coins = {};
        this.stars = {};
        this.letters = {};
        this.highlightedShine = {};
        this.highlightedLetters = {};
        this.options = options || {};

        this.setAnchorPoint(0.5, 0.5);
        this.setGrid(this.board.gridType === "crossword" ? cleverapps.CrosswordGrid : cleverapps.WordPerlineGrid);
        this.createBackground();

        if (board.game.currentHighlighter) {
            this.currentHighlighterView = new CurrentHighlighterView(board.game.currentHighlighter, this);
            this.grid.addChild(this.currentHighlighterView);
        }

        var eyeBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_EYE);

        if (eyeBooster) {
            this.eyeBoosterView = new EyeBoosterView(eyeBooster, this);
            this.grid.addChild(this.eyeBoosterView);
        }

        this.visible = false;
        board.on("beforeDiscover", this.beforeDiscover.bind(this), this);
        board.on("afterDiscover", this.afterDiscover.bind(this), this);
        board.on("addCoin", this.addCoin.bind(this), this);
        board.on("collectCoin", this.collectCoin.bind(this), this);
        board.on("addStar", this.addStar.bind(this), this);
        board.on("collectStar", this.collectMissionReward.bind(this), this);
        board.on("lastWord", this.animateLastWord.bind(this), this);
        board.on("alreadyDiscovered", this.animateAlreadyDiscovered.bind(this), this);
        board.on("showUp", this.showUpAnimation.bind(this), this);
        board.on("showUpLetters", this.showUpLettersAnimation.bind(this), this);
        board.on("showUpCoins", this.showUpCoinsAnimation.bind(this), this);
        board.on("showUpStars", this.showUpStarsAnimation.bind(this), this);
        board.on("hide", this.hideAnimation.bind(this), this);
        board.on("openLetter", this.animateOpenLetter.bind(this), this);
        board.on("highlightLetter", this.highlightLetter.bind(this), this);
        board.on("clearHighlight", this.clearHighlight.bind(this), this);
        board.on("setColumnsAmount", this.setColumnsAmount.bind(this), this);

        board.getView = this.createListener(function () {
            return this;
        }, this);
    },

    setGrid: function (GridClass) {
        if (this.grid) {
            this.removeChild(this.grid);
        }

        this.grid = new GridClass(this.board.field);
        this.addChild(this.grid);
    },

    setColumnsAmount: function (columnsCount) {
        if (this.grid.setColumnsAmount) {
            this.grid.setColumnsAmount(columnsCount);
            this.updateHighlightLetter();
        }
    },

    updateGrid: function (availableRect) {
        var styles = cleverapps.styles.BoardView;
        var padding = cc.padding(styles.Background.padding);

        var gridRect = cc.rectSubPadding(availableRect, padding);

        if (this.grid.updateGrid) {
            this.grid.updateGrid(gridRect);
        }
        cleverapps.UI.fitToBox(this.grid, gridRect);

        this.setContentSize(availableRect.width, availableRect.height);
        this.setPositionRound(availableRect.x + availableRect.width / 2, availableRect.y + availableRect.height / 2);

        this.grid.setPositionRound(this.width / 2, (this.height - padding.top - padding.bottom) / 2 + padding.bottom);
        this.updateHighlightLetter();
    },

    elementsHide: function () {
        Object.values(this.coins).forEach(function (view) {
            var scale = view.getScale();
            view.runAction(new cc.Spawn(
                new cc.CallFunc(function () {
                    view.setTimeScale(1);
                    view.setAnimation(0, "fadeout", false);
                }),
                new cc.ScaleTo(0.3, scale * 0.7)
            ));
        });

        var views = Object.values(this.stars)
            .concat(Object.values(this.letters))
            .concat(Object.values(this.grid.listCells(cleverapps.Board.PLACEHOLDER_LAYER)));

        views.forEach(function (view) {
            var scale = view.getScale();
            if (view.highlightAnimation) {
                view.setOpacity(255);
                view.highlightAnimation.removeFromParent();
            }
            view.runAction(new cc.Spawn(
                new cc.FadeOut(0.3),
                new cc.ScaleTo(0.3, scale * 0.7)
            ));
        });
    },

    hideAnimation: function (f) {
        this.elementsHide();
        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.3),
            new cc.CallFunc(function () {
                this.setVisible(false);
                f();
            }.bind(this))
        ));
    },

    updateBackground: function () {
        var styles = cleverapps.styles.BoardView;

        var padding = cc.padding(styles.Background.padding);
        var scale = this.grid.getScale();

        var width = this.grid.width * scale + padding.left + padding.right;
        if (styles.Background.width === "100%") {
            width = cleverapps.resolution.getSceneSize().width * 1.05;
        }

        var height = this.grid.height * scale + padding.top + padding.bottom;

        if (this.boardBg) {
            this.boardBg.setContentSize2(width, height);
            this.boardBg.setPositionRound(this.width / 2, this.height / 2);
        }

        if (this.bgTop) {
            this.bgTop.setContentSize2(width, this.bgTop.height);
            this.bgTop.setPositionRound(styles.BgTop);
        }
    },

    createBackground: function () {
        var boardBgImg = (this.board.level.isHard() && bundles.game.frames.hard_board_png) || bundles.game.frames.board_png;
        if (boardBgImg) {
            this.boardBg = cleverapps.UI.createScale9Sprite(boardBgImg);
            this.addChild(this.boardBg);
            this.boardBg.setLocalZOrder(-1);
        }

        var boardBgTopImg = bundles.game.frames.bg_board_top_png;
        if (cleverapps.isRumble() && cleverapps.meta.getRumble().getCurrentRound().isLast()) {
            boardBgTopImg = bundles.game.frames.bg_board_top_hard_png;
        }
        if (boardBgTopImg) {
            var bgTop = this.bgTop = new cc.Scale9Sprite(boardBgTopImg);
            this.addChild(bgTop);
            bgTop.setLocalZOrder(-1);
        }
    },

    animateAlreadyDiscovered: function (indexes) {
        var shake = function (element) {
            if (!element || !element.isRunning()) {
                return;
            }
            var position = element.getPosition();
            var dx = cleverapps.styles.BoardView.shakeAnimation.dx;
            element.runAction(new cc.Sequence(
                new cc.MoveTo(0.06, position.x - dx, position.y).easing(cc.easeIn(1)),
                new cc.MoveTo(0.06, position.x + dx, position.y).easing(cc.easeIn(1)),
                new cc.MoveTo(0.06, position.x - dx, position.y).easing(cc.easeIn(1)),
                new cc.MoveTo(0.06, position.x + dx, position.y).easing(cc.easeIn(1)),
                new cc.MoveTo(0.03, position.x, position.y).easing(cc.easeIn(1))
            ));
        };

        indexes.forEach(function (pos) {
            var row = pos[0];
            var col = pos[1];

            shake(this.grid.getCell(row, col));
            shake(this.letters[row + "_" + col]);
        }, this);
    },

    animateLastWord: function (indexes) {
        var animatePhantomLetter = function (index) {
            var styles = cleverapps.styles.BoardView.phantomLetter;

            if (!indexes[index]) {
                return;
            }

            var row = indexes[index][0];
            var col = indexes[index][1];

            var phantomLetter = new cc.Sprite(bundles.letters.frames.letter_bg_png);
            phantomLetter.setOpacity(0);
            phantomLetter.setLocalZOrder(5);
            this.grid.putInCell(row, col, phantomLetter);

            animatePhantomLetter(index + 1);

            phantomLetter.runAction(new cc.Sequence(
                new cc.DelayTime(0.2 * index / 2),
                new cc.CallFunc(function () {
                    if (this.board.isComplete()) {
                        phantomLetter.stopAllActions();
                    }
                }.bind(this)),
                new cc.Spawn(
                    new cc.FadeTo(0.2, styles.opacity),
                    new cc.MoveBy(0.2, 0, styles.dy)
                ),
                new cc.Spawn(
                    new cc.MoveBy(0.2, 0, -styles.dy),
                    new cc.FadeOut(0.2)
                ),
                new cc.RemoveSelf()
            ));
        }.bind(this);

        var animateLine = function (actionIndex) {
            if (!Game.currentGame || Game.currentGame.outcome !== undefined || this.board.isComplete()) {
                return;
            }
            cleverapps.audio.playSound(bundles.game.urls.last_word_effect);
            animatePhantomLetter(actionIndex);

            this.runAction(
                new cc.Sequence(
                    new cc.DelayTime(10.0),
                    new cc.CallFunc(function () {
                        animateLine(0);
                    })
                )
            );
        }.bind(this);

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(2.0),
                new cc.CallFunc(function () {
                    animateLine(0);
                })
            )
        );
    }
});

cleverapps.BoardView.prototype.addStar = function (row, col, type) {
    var icon = this.stars[row + "_" + col] = new cc.Sprite(cleverapps.skins.getSlot("missionStar", {
        type: type
    }) || bundles.game.frames["mission_star" + type + "_png"]);

    this.grid.putInCell(row, col, icon);
    icon.setLocalZOrder(6);
};

cleverapps.BoardView.prototype.addCoin = function (row, col) {
    var coinId = row + "_" + col;
    var coin = this.coins[coinId] = new cleverapps.Spine(bundles.game.jsons.coin_json);
    coin.order = col;
    coin.setAnchorPoint(0.5, 0.5);
    coin.setAnimation(0, "shine", true);
    coin.setTimeScale(0.5 + Math.random());
    coin.setAnchorPoint(0.5, 0.47);
    this.grid.putInCell(row, col, coin);
    coin.setLocalZOrder(6);
};

cleverapps.BoardView.prototype.collectCoin = function (row, col) {
    var coinId = row + "_" + col;
    var coin = this.coins[coinId];
    delete this.coins[coinId];

    if (!coin) {
        return;
    }

    var reward = new Reward("hard", WordGame.BOARD_COIN, {
        event: cleverapps.EVENTS.EARN.BOARD_COIN
    });
    reward.receiveReward();
    reward.collectRewardsAnimation(coin, {
        flyingAnimation: Reward.COLLECT_ANIMATION
    });
};

cleverapps.BoardView.prototype.collectMissionReward = function (row, col) {
    var id = row + "_" + col;
    var star = this.stars[id];
    delete this.stars[id];

    if (!star) {
        return;
    }

    var missionType = Game.currentGame.getMissionType();
    star.runAction(
        new cc.Sequence(
            AnimationsLibrary.animateCollect(star, "mission_reward" + missionType, {
                duration: cleverapps.Board.FLIGHT_DURATION / 1000
            }),
            new cc.RemoveSelf()
        )
    );

    star.runAction(new cc.RepeatForever(
        new cc.RotateBy(0.5, 360)
    ));
};

cleverapps.BoardView.prototype.createLetter = function (char, options) {
    options = options || {};

    options.noBg = cleverapps.config.subtype === "stacks";
    return CharView.Create(char, options);
};

cleverapps.BoardView.prototype.playOpenLetterSound = cleverapps.throttle(0.1, function () {
    cleverapps.audio.playSound(bundles.game.urls.letter_effect);
});

cleverapps.BoardView.prototype.beforeDiscover = function () {};

cleverapps.BoardView.prototype.afterDiscover = function (word, silent, callback) {
    callback = callback || function () {};

    if (silent) {
        callback();
        return;
    }

    this.runAction(
        new cc.Sequence(
            new cc.PlaySound(bundles.game.urls.letters_effect),
            new cc.DelayTime(0.1),
            new cc.CallFunc(callback)
        )
    );
};

cleverapps.BoardView.prototype.animateOpenLetter = function (row, col, options, callback) {
    callback = callback || function () {};

    var letter = this.board.field[row].charAt(col);

    if (options.silent) {
        this.addLetter(letter, row, col, callback);
        return;
    }

    switch (options.type) {
        case cleverapps.Board.HINT_LETTER_ANIMATION:
            this.animateHintLetter(row, col);
            break;

        case cleverapps.Board.DISCOVER_LETTER_ANIMATION:
            if (cleverapps.config.name === "olympics") {
                this.animateDiscoverLetter(letter, row, col, callback);
            } else {
                this.animateFlyLetter(letter, row, col, callback);
            }
            break;
    }
};

cleverapps.BoardView.prototype.animateDiscoverLetter = function (letter, row, col, callback) {
    var letterSprite = this.addLetter(letter, row, col);

    if (!letterSprite) {
        return;
    }

    var shine = new cleverapps.Spine(bundles.game.jsons.letter_particle);
    shine.setAnimation(0, "animation", false);
    shine.setPosition(letterSprite.width / 2, letterSprite.height / 2);
    shine.setLocalZOrder(1);
    letterSprite.addChild(shine);
    letterSprite.setOpacity(0);

    letterSprite.runAction(
        new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.FadeTo(0.2, 255),
            new cc.ScaleTo(0.5, 1),
            new cc.CallFunc(callback)
        )
    );

    var letterId = row + "_" + col;
    this.letters[letterId] = letterSprite;

    this.playOpenLetterSound();
};

cleverapps.BoardView.prototype.animateHintLetter = function (row, col, start) {
    var target = this.grid.getCell(row, col, cleverapps.Board.LETTER_LAYER);
    if (!target) {
        return;
    }

    var letterSprite = this.createLetter(this.board.field[row].charAt(col));
    var letterId = row + "_" + col;
    this.letters[letterId] = letterSprite;

    cleverapps.UI.animateLetterHintFly.call(this, target, letterSprite, {
        start: start,
        letterZOrder: row
    });
};

cleverapps.BoardView.prototype.addLetter = function (letter, row, col, callback) {
    callback = callback || function () {};

    var letterSprite = this.createLetter(letter);
    letterSprite.setAnchorPoint(0.5, 0.49);

    if (this.grid.isExistsCell(row, col)) {
        this.grid.putInCell(row, col, letterSprite, row, cleverapps.Board.LETTER_LAYER);

        var letterId = row + "_" + col;
        this.letters[letterId] = letterSprite;

        callback();
        return letterSprite;
    }
};

cleverapps.BoardView.prototype.animateFlyLetter = function (letter, row, col, callback) {
    var letterView = this.board.game.current.getLetterView(letter);

    this.playOpenLetterSound();

    if (!letterView || !letterView.isRunning()) {
        this.addLetter(letter, row, col, callback);
        return;
    }

    var target = this.grid.getCell(row, col, cleverapps.Board.LETTER_LAYER);

    letterView.stopAllActions();
    letterView.setOpacity(255);
    letterView.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this), {
        keepScale: true
    });

    var letterPosition = letterView.getPosition();
    var targetPosition = target.getPosition();

    var first = letterPosition;
    var third = letterView.getParent().convertToNodeSpace(target.getParent().convertToWorldSpace(targetPosition));
    var second = cc.p((first.x + 2 * third.x) / 3, (first.y + 2 * third.y) / 3 - 10);
    var flyTime = cc.pDistance(first, third) / 1750;

    var shine = new cleverapps.Spine(bundles.game.jsons.letter_particle);
    shine.setAnimation(0, "animation", false);
    shine.setPosition(letterView.width / 2, letterView.height / 2);
    shine.setLocalZOrder(1);
    letterView.addChild(shine);

    letterView.runAction(new cc.Sequence(
        new cc.DelayTime((cleverapps.Random.random(0, 200)) / 1000),
        new cc.Spawn(
            new cc.BezierTo(flyTime, [first, second, third]).easing(cc.easeInOut(1)),
            new cc.ScaleTo(flyTime, 1).easing(cc.easeInOut(1))
        ),
        new cc.ReplaceParent(target),
        new cc.ScaleTo(0.13, 1.2).easing(cc.easeInOut(1)),
        new cc.ScaleTo(0.13, 1).easing(cc.easeInOut(1)),
        new cc.CallFunc(callback)
    ));

    var letterId = row + "_" + col;
    this.letters[letterId] = letterView;
};

cleverapps.BoardView.prototype.highlightLetter = function (row, col) {
    if (this.grid.isExistsCell(row, col)) {
        var letter = this.board.field[row].charAt(col);

        var bgImage = bundles.letters.frames.letter_bg_highlighted_png;

        var letterSprite = this.createLetter(letter, {
            bgImage: bgImage
        });

        var highlightSprite = new cc.Sprite(bundles.letters.frames.highlighted_png);

        var letterId = row + "_" + col;
        this.highlightedLetters[letterId] = letterSprite;
        this.highlightedShine[letterId] = highlightSprite;
        this.grid.addChild(highlightSprite, row - 1);
        this.grid.addChild(letterSprite, row + 1);
    }
};

cleverapps.BoardView.prototype.updateHighlightLetter = function () {
    Object.keys(this.highlightedLetters).forEach(function (key) {
        var rowAndCol = key.split("_");
        var row = Number.parseInt(rowAndCol[0]);
        var col = Number.parseInt(rowAndCol[1]);

        var cell = this.grid.getCell(row, col);

        this.highlightedLetters[key].setPositionRound(cell.x, cell.y + cleverapps.styles.BoardView.Letter.paddingBottom);
        this.highlightedShine[key].setPositionRound(cell.x, cell.y + cleverapps.styles.BoardView.Letter.paddingBottom);
    }.bind(this));
};

cleverapps.BoardView.prototype.clearHighlight = function () {
    Object.keys(this.highlightedLetters).forEach(function (letterId) {
        if (this.highlightedLetters[letterId]) {
            this.highlightedLetters[letterId].removeFromParent();
            delete this.highlightedLetters[letterId];
        }

        if (this.highlightedShine[letterId]) {
            this.highlightedShine[letterId].removeFromParent();
            delete this.highlightedShine[letterId];
        }
    }.bind(this));
};

cleverapps.styles.BoardView = {
    minSpaceX: 20,
    maxSpaceX: 100,
    minSpaceY: 10,

    padding: {
        x: 15,
        top: 15,
        bottom: 20
    },

    Letter: {
        offset: 5,
        paddingBottom: 0
    },
    Coin: {
        paddingBottom: 3,
        showUpOffset: 25
    },
    Background: {
        width: "100%",

        padding: {
            x: 25,
            y: 25
        }
    },
    shakeAnimation: {
        dx: 12
    },
    phantomLetter: {
        opacity: 180,
        dy: 5
    },

    bezier: {
        y: 10
    }
};
