var ButterflyWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        this.mission.logic.updateState();

        var styles = cleverapps.styles.ButterflyWindow;

        var image = this.createImage();
        var description = this.createDescription();
        var progress = this.progressBar = this.createProgress();

        var content = new cleverapps.Layout([image, description, progress], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this._super({
            mission: mission,
            title: "ButterflyWindow.title",
            name: "butterflywindow",
            content: content,
            closeButton: !this.mission.logic.isProgressFull(),
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: this.mission.logic.getButtonText(),
                type: cleverapps.styles.UI.Button.Images.button_green,
                onPressed: this.onButtonPressed.bind(this)
            },
            help: function () {
                return new GuideWindow({
                    name: "ButterflyGuideWindow",
                    bundle: bundles.butterflyguidewindow
                });
            }
        });

        this.showAnimations();
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BUTTERFLY_WINDOW_OPENED);
    },

    showAnimations: function () {
        new ActionPlayer([
            function (f) {
                if (!this.mission.logic.rewardReseived) {
                    this.progressBar.showUp(f);
                } else {
                    f();
                }
            }.bind(this),
            function (f) {
                if (this.mission.logic.getCurrentStageResult() !== this.mission.logic.lastShownResult) {
                    cleverapps.audio.playSound(bundles.butterfly_window.urls.butterfly_load);
                    this.progressBar.updateProgress(this.mission.logic.getCurrentStageResult(), this.mission.logic.getGoal(), {
                        animated: true,
                        delta: true
                    });
                    f();
                    this.mission.logic.onProgressBarFill();
                } else {
                    f();
                }
            }.bind(this)
        ]).play();
    },

    onButtonPressed: function () {
        if (!this.mission.logic.isProgressFull()) {
            cleverapps.meta.distract({
                focus: "MissionPlayButtonPressed",
                action: function (f) {
                    cleverapps.meta.wantsToPlay(f);
                }
            });
        }

        this.progressBar.onClick();
    },

    closeAction: function () {
        if (this.mission.logic.isReadyToFinish()) {
            this.mission.complete();
        }

        if (!this.mission.logic.isProgressFull()) {
            this.close();
            return;
        }

        this.mission.logic.claimReward();
        this.close();
    },

    createTimer: function () {
        return new Timer(this.mission.getTimeLeft(), function () {}, {
            timerBg: bundles.butterfly_window.frames.big_timer_bg_png,
            timerIcon: bundles.butterfly_window.frames.big_timer_png
        });
    },

    createImage: function () {
        var styles = cleverapps.styles.ButterflyWindow;
        var layout = new cc.Node();
        var timer = this.createTimer();
        var image = new cc.Sprite(bundles.butterfly_window.frames.image);

        layout.addChild(timer);
        layout.addChild(image);
        layout.setContentSize(layout.width, image.height - styles.image.height);
        timer.setPositionRound(styles.timer.position);
        timer.setLocalZOrder(1);
        image.setPositionRound(styles.image.position);
        return layout;
    },

    createDescription: function () {
        var styles = cleverapps.styles.ButterflyWindow;

        var text = cleverapps.UI.generateOnlyText("ButterflyWindow.description", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);

        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);
        text.fitTo(undefined, styles.text.height);
        return text;
    },

    createProgress: function () {
        var progressBar = new PrizeBarComponent({
            tooltipText: Messages.get("ButterflyWindow.tooltip") + ": " + this.mission.logic.stage + "/" + ButterflyLogic.STAGES_COUNT,
            reward: this.mission.logic.getPrize(),
            prize: this.mission.logic.getPrizeSpine(),
            onCollect: this.closeAction.bind(this)
        });

        if (this.mission.logic.lastShownStage !== this.mission.logic.stage) {
            progressBar.updateProgress(0, this.mission.logic.getGoal(this.mission.logic.stage));
            this.mission.logic.onProgressBarReload();
        } else {
            progressBar.updateProgress(this.mission.logic.lastShownResult, this.mission.logic.getGoal(this.mission.logic.lastShownStage));
        }

        var styles = cleverapps.styles.ButterflyWindow.progress;
        var icon = new cc.Sprite(bundles.butterfly_window.frames.rewardIcon);

        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.baseScale = icon.scale;

        icon.setPositionRound(styles.icon);
        progressBar.addChild(icon);

        return progressBar;
    }
});

ButterflyWindow.prototype.listBundles = function () {
    return ["butterfly_window"];
};

cleverapps.styles.ButterflyWindow = {
    margin: 20,

    image: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 25 }
        },

        height: -10
    },

    text: {
        width: 500,
        height: 150
    },

    timer: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 0 }
        }
    },

    button: {
        width: 260,
        height: 100
    },

    progress: {
        icon: {
            x: { align: "left", dx: -20 },
            y: { align: "center", dy: 0 },

            width: 90,
            height: 85
        }
    }
};