/**
 * Created by slava on 23.03.17.
 */

var KeypadView = cc.Node.extend({
    avoidNode: "KeypadView",
    ctor: function (keypad) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.keypad = keypad;

        this.lettersContainer = new cc.Node();
        this.lettersContainer.setAnchorPoint(0.5, 0.5);
        this.addChild(this.lettersContainer);

        this.animationsContainer = new cc.Node();
        this.animationsContainer.setAnchorPoint(0.5, 0.5);
        this.addChild(this.animationsContainer);

        this.createField();
        this.addTouchListener();

        keypad.on("tutorialStarted", this.drawTutorialWord.bind(this), this);
        keypad.on("tutorialStopped", this.removeTutorialWord.bind(this), this);
        keypad.on("force", this.showForce.bind(this), this);
        keypad.on("shuffle", this.shuffle.bind(this), this);
        keypad.on("status", this.status.bind(this), this);
        keypad.on("blockAdded", this.addBlockView.bind(this), this);
        keypad.on("touchStopped", this.removeTouchListener.bind(this), this);
        keypad.on("resize", this.resize.bind(this), this);
        keypad.on("hide", this.hide.bind(this), this);
        keypad.on("push", this.onPush.bind(this), this);
        keypad.on("pop", this.onPop.bind(this), this);
        keypad.on("touchBegan", function () {
            this.removeTutorialWord();
        }.bind(this), this);

        this.resize(true);

        this.createSounds();

        this.debugId = "Keypad";
    },

    createSounds: function () {
        this.sounds = [];
        var soundId = 0;
        while (bundles.game.urls["nota_" + soundId]) {
            this.sounds.push(bundles.game.urls["nota_" + soundId]);
            soundId++;
        }
    },

    updateSize: function () {
        this.resize(true);
    },

    onPush: function () {
        cleverapps.audio.playSound(this.sounds[(this.keypad.indexes.length - 1) % this.sounds.length]);
    },

    onPop: function () {
        cleverapps.audio.playSound(this.sounds[this.keypad.indexes.length % this.sounds.length]);
    },

    hide: function (f) {
        if (cleverapps.flags.keypadOnWinDisabled) {
            f();
            return;
        }

        this.setCascadeOpacityEnabledRecursively(true);

        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.2),
            new cc.Hide(),
            new cc.CallFunc(f)
        ));
    },

    status: function (code, letters) {
        if (code === WordGame.STATUSES.BOARD) {
            letters.forEach(function (letter, order) {
                var animation = new cleverapps.Spine(bundles.game.jsons.remove_letter_sparkles_json);
                this.animationsContainer.addChild(animation);
                animation.setPositionRound(BaseBlockView.alignInTheGrid(letter.x, letter.y));

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.075 * order + 0.1),
                    new cc.CallFunc(function () {
                        animation.setAnimation(0, "animation", false);
                        animation.setCompleteListenerRemove();
                    })
                ));
            }.bind(this));
        }

        if (code === WordGame.STATUSES.BOARD || code === WordGame.STATUSES.EXTRA) {
            var scene = cleverapps.scenes.getRunningScene();

            var pos1 = BaseBlockView.alignInTheGrid(letters[0].x, letters[0].y);
            var pos2 = BaseBlockView.alignInTheGrid(letters[letters.length - 1].x, letters[letters.length - 1].y);
            pos1 = scene.convertToNodeSpace(this.lettersContainer.convertToWorldSpace(pos1));
            pos2 = scene.convertToNodeSpace(this.lettersContainer.convertToWorldSpace(pos2));
            KeypadView.LAST_WORD_POSITION = cc.p((pos1.x + pos2.x) / 2, (pos1.y + pos2.y) / 2);
        }
    },

    showForce: function (f, force) {
        if (cleverapps.forces.isAvailable(this, force)) {
            cleverapps.forces.create(this, force);
            cleverapps.forces.onceForceClosed = this.createListener(function () {
                this.removeTutorialWord();
                f();
            });
        } else {
            f();
        }
    },

    removeTouchListener: function () {
        if (this.touchListener) {
            this.touchListener.remove();
            delete this.touchListener;
        }
    },

    addBlockView: function (block) {
        if (block === undefined) {
            return;
        }
        var view = BaseBlockView.CreateView(block);
        this.lettersContainer.addChild(view);
    },

    createField: function () {
        this.keypad.field.forEach(function (column) {
            column.forEach(this.addBlockView, this);
        }, this);
    },

    resize: function (silent) {
        var newScale, newPosition;
        var oldSize = this.getContentSize();
        this.setContentSize(this.keypad.getWidth() * cleverapps.styles.BaseBlockView.width, this.keypad.getHeight() * cleverapps.styles.BaseBlockView.height);

        var styles = cleverapps.styles.KeypadView.phone;
        if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
            styles = cleverapps.styles.KeypadView.nonphone;
        }

        var sceneSize = cleverapps.resolution.getSceneSize();

        var availableWidth = sceneSize.width;

        var scaleX = (availableWidth - styles.paddingLeft - styles.paddingRight) / this.width;
        var scaleY = styles.height / this.height;
        newScale = Math.min(scaleX, scaleY, 1);
        var leftX = sceneSize.width - availableWidth + styles.paddingLeft;
        var rightX = sceneSize.width - styles.paddingRight;
        var posX = (leftX + rightX) / 2;
        newPosition = cc.p(posX, this.height / 2 * newScale + styles.paddingBottom);

        this.setPositionRound(this.x + (this.width * this.scale - oldSize.width * this.scale) / 2, this.y + (this.height * this.scale - oldSize.height * this.scale) / 2);

        if (silent) {
            this.setScale(newScale);
            this.setPositionRound(newPosition);
        } else {
            this.runAction(new cc.Spawn(
                new cc.ScaleTo(0.7, newScale),
                new cc.MoveTo(0.7, newPosition)
            ));
        }

        this.animationsContainer.setContentSize2(this.getContentSize());
        this.animationsContainer.setPositionRound(this.width / 2, this.height / 2);

        this.lettersContainer.setContentSize2(this.getContentSize());
        this.lettersContainer.setPositionRound(this.width / 2, this.height / 2);
    },

    removeTutorialWord: function () {
        FingerView.remove(this.finger, true);
        this.finger = undefined;

        if (this.tutorialBlocks) {
            this.tutorialBlocks.forEach(function (block) {
                block.unhighlight();
            });

            delete this.tutorialBlocks;
        }
    },

    drawTutorialWord: function (blocks, options) {
        if (FingerView.isRunning(this.finger)) {
            return;
        }

        options = options || {};

        this.tutorialBlocks = blocks;

        this.finger = FingerView.hintDrag(blocks, {
            callback: options.callback,
            runOnce: options.looped === undefined ? false : !options.looped
        });
    },

    shuffle: function () {
        var views = this.lettersContainer.children;

        views.forEach(function (view) {
            view.stopAllActions();
            var newPos = BaseBlockView.alignInTheGrid(view.block.x, view.block.y);

            view.setLocalZOrder(view.block.y);

            var dist = Math.sqrt(Math.pow(newPos.x - this.x, 2) + Math.pow(newPos.y - this.y, 2));

            view.runAction(
                new cc.Spawn(
                    new cc.MoveTo(dist / 700, newPos).easing(cc.easeBackInOut(0.6)),
                    new cc.ScaleTo(0.3, 1)
                )
            );
        }, this);

        var animation = new cleverapps.Spine(bundles.game.jsons.shuffle_json);
        cleverapps.scenes.getRunningScene().addChild(animation);
        animation.setPositionRound(cleverapps.styles.KeypadView.shuffleAnimation);
        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();

        cleverapps.audio.playSound(bundles.game.urls.shuffle_button_effect);
    },

    addTouchListener: function () {
        this.removeTouchListener();
        this.touchListener = cleverapps.UI.onDrag(this, {
            instantDrag: true,
            onDragStart: this.onTouchBegan.bind(this),
            followPointer: this.onTouchMoved.bind(this),
            onDragEnd: this.onTouchEnded.bind(this)
        }, this);
    },

    onTouchBegan: function (touch) {
        if (this.touchId !== undefined) {
            return false;
        }

        var location = this.convertTouchToNodeSpace(touch);
        if (cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), location)) {
            var letter = this.calcLetterTouched(location);
            if (letter) {
                var res = this.keypad.touchBegan(letter.block);
                if (res) {
                    this.touchId = touch.getID();
                    this.startLetter = letter;
                    return true;
                }
            }
        }

        return false;
    },

    onTouchMoved: function (touch) {
        if (touch.getID() !== this.touchId) {
            return false;
        }

        var move = this.calcDirection(this.startLetter, this.convertTouchToNodeSpace(touch));
        if (move) {
            this.keypad.touchMoved(this.startLetter.block, move);
            return true;
        }
    },

    onTouchEnded: function (touch) {
        if (touch.getID() !== this.touchId) {
            return false;
        }

        this.keypad.touchEnded();
        this.touchId = undefined;
        this.startLetter = undefined;
        return true;
    }
});

KeypadView.prototype.calcLetterTouched = function (touchPos) {
    var col = Math.floor(touchPos.x / cleverapps.styles.BaseBlockView.width);
    var row = Math.floor(touchPos.y / cleverapps.styles.BaseBlockView.height);

    if (!this.keypad.field[col] || !this.keypad.field[col][row] || !(this.keypad.field[col][row] instanceof LetterBlock)) {
        return undefined;
    }

    return this.keypad.field[col][row].onLocateView();
};

KeypadView.prototype.calcDirection = function (start, end) {
    if (!cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), end)) {
        return;
    }

    var dx = end.x - start.x;
    var dy = end.y - start.y;

    var minDist = Number.MAX_VALUE;
    var minDirection;
    for (var dirNo = 0; dirNo < BlockMixer.DIRECTIONS.length; dirNo++) {
        var direction = BlockMixer.DIRECTIONS[dirNo];

        var dist = Math.pow((dx - direction.x), 2) + Math.pow((dy - direction.y), 2);

        if (dist < minDist) {
            minDist = dist;
            minDirection = direction;
        }
    }

    dx *= minDirection.x;
    dy *= minDirection.y;

    var length = Math.round(dx / cleverapps.styles.BaseBlockView.width) + Math.round(dy / cleverapps.styles.BaseBlockView.height);

    return {
        direction: minDirection,
        length: length + 1
    };
};

cleverapps.styles.KeypadView = {
    letter: {
        width: 100,
        height: 100
    },

    shuffleAnimation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -100 }
    },

    phone: {
        paddingBottom: 145,
        paddingLeft: 12,
        paddingRight: 12,
        height: 640
    },

    nonphone: {
        paddingBottom: 60,
        paddingLeft: 270,
        paddingRight: 270,
        height: 750
    }
};
