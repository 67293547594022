/**
 * Created by vladislav on 06.07.20.
 */

var LetterBlock = function (x, y, letterData, keypad) {
    BaseBlock.call(this, x, y, keypad);
    this.symbol = letterData;
};

LetterBlock.prototype = Object.create(BaseBlock.prototype);
LetterBlock.prototype.constructor = LetterBlock;

LetterBlock.prototype.getViewClass = function () {
    return LetterBlockView;
};

LetterBlock.prototype.status = function (code, direction) {
    if (code === WordGame.STATUSES.WRONG) {
        this.trigger("wrong", direction);
    }

    if (code === WordGame.STATUSES.BOARD) {
        this.trigger("board");
    }
};

LetterBlock.prototype.unhighlight = function () {
    this.trigger("unhighlight");
};

LetterBlock.prototype.highlight = function () {
    this.trigger("highlight");
};

LetterBlock.prototype.setHint = function (hint, options) {
    if (this.hint !== hint) {
        this.hint = hint;

        this.trigger("setHint", options);
    }
};

LetterBlock.prototype.isHintSet = function () {
    return this.hint;
};

LetterBlock.prototype.addMagnifier = function () {
    this.magnifier = new Magnifier(this.game);

    this.trigger("addMagnifier");
};