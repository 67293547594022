/**
 * Created by vladislav on 29.10.2020
 */

var BaseBlockView = cc.Node.extend({
    ctor: function (block) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.block = block;

        var styles = cleverapps.styles.BaseBlockView;

        this.setContentSize2(styles);
        this.setAnchorPoint(0.5, 0.5);

        this.setPositionRound(BaseBlockView.alignInTheGrid(this.block.x, this.block.y));

        this.setCascadeOpacityEnabled(true);

        this.setLocalZOrder(this.block.y);

        this.block.on("move", this.createListener(this.move.bind(this)));
        this.block.on("lose", this.createListener(this.lose.bind(this)));
        this.block.on("remove", this.createListener(this.removeFromParent.bind(this, true)));
        this.block.onLocateView = this.createListener(this.locateView.bind(this));

        this.setVisible(false);
    },

    locateView: function () {
        return this;
    },

    move: function (duration) {
        this.fall(duration, BaseBlockView.alignInTheGrid(this.block.x, this.block.y));
    },

    fall: function (duration, pos) {
        this.stopAllActions();
        this.runAction(new cc.MoveTo(duration, pos).easing(cc.easeIn(2)));
    },

    lose: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var pos = this.getParent().convertToNodeSpace(scene.convertToWorldSpace(cc.p(0, -2 * this.height)));
        this.fall(this.block.getLoseDuration(), cc.p(this.x, pos.y));
        this.runAction(new cc.Sequence(
            new cc.DelayTime(this.block.getLoseDuration()),
            new cc.PlaySound(bundles.game.urls.drop_lose_effect, { throttle: 1000 })
        ));
    },

    showUpAnimation: function () {
        var delay = this.block.getShowUpDuration();
        this.fall(delay, BaseBlockView.alignInTheGrid(this.block.x, this.block.y));
        this.runAction(new cc.FadeIn(0.4));
        if (this.sound) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.PlaySound(this.sound)
            ));
        }
    },

    showUp: function (silent) {
        if (silent) {
            this.setVisible(true);
            return;
        }
        var scene = cleverapps.scenes.getRunningScene();
        var pos = this.getParent().convertToNodeSpace(scene.convertToWorldSpace(cc.p(0, scene.height / 3)));
        this.setPositionRound(this.x, pos.y + this.y);
        this.setOpacity(0);
        this.setVisible(true);
        this.showUpAnimation();
    }
});

BaseBlockView.GetView = function (block) {
    return block.onLocateView();
};

BaseBlockView.CreateView = function (block) {
    var ViewClass = block.getViewClass();
    return new ViewClass(block);
};

BaseBlockView.alignInTheGrid = function (col, row) {
    return cc.p((col + 0.5) * cleverapps.styles.BaseBlockView.width, (row + 0.5) * cleverapps.styles.BaseBlockView.height);
};

cleverapps.styles.BaseBlockView = {
    width: 100,
    height: 98
};