/**
 * Created by vladislav on 24.11.2020
 */

var KeypadLetterView = cc.Node.extend({
    ctor: function (letter, radius) {
        this._super();

        this.letter = letter;

        this.radius = radius;

        var styles = cleverapps.styles.KeypadLetterView;

        this.setAnchorPoint(0.5, 0.5);

        this.setContentSize2(styles);

        this.charView = CharView.Create(this.letter.symbol, {
            noBg: styles.noBg,
            bgImage: KeypadLetterView.bgImage,
            whiteLetter: true
        });

        this.addChild(this.charView);

        this.charView.setPositionRound(this.width / 2, this.height / 2);

        if (bundles.letters.frames.letter_bg_glow_png) {
            this.glow = new cc.Sprite(bundles.letters.frames.letter_bg_glow_png);
            this.addChild(this.glow);
            this.glow.setPositionRound(this.width / 2, this.height / 2);
            this.glow.setLocalZOrder(-1);
            this.glow.setOpacity(0);
        }

        if (cleverapps.config.name === "wordsoup") {
            var rotations = [-4, 4, 0, -6, 6];

            this.setRotation(rotations[this.letter.symbol.charCodeAt(0) % rotations.length]);
        }

        this.baseRotation = this.getRotation();

        this.letter.on("select", this.select.bind(this), this);
        this.letter.on("unselect", this.unselect.bind(this), this);
        this.letter.on("status", this.status.bind(this), this);
        this.letter.onLocateView = this.createListener(this.locateView.bind(this));

        this.setCascadeOpacityEnabled(true);
        this.setCascadeColorEnabled(true);

        this.setupChildren();
    },

    setupChildren: function () {
        this.setPositionRound(this.calcPosition(this.letter.index));
    },

    locateView: function () {
        return this;
    },

    select: function () {
        if (this.glow) {
            this.glow.stopAllActions();
            this.glow.runAction(new cc.FadeTo(0.2, 255));
        }

        if (cleverapps.config.name === "wordsoup") {
            this.runAction(new cc.Spawn(new cc.ScaleTo(0.2, 1.2), new cc.RotateTo(0.2, this.baseRotation - 4)));
        }
    },

    unselect: function () {
        if (this.glow) {
            this.glow.stopAllActions();
            this.glow.setOpacity(255);
            this.glow.runAction(new cc.Sequence(new cc.DelayTime(0.1), new cc.FadeTo(0.3, 0)));
        }

        if (cleverapps.config.name === "wordsoup") {
            this.runAction(new cc.Spawn(new cc.ScaleTo(0.2, 1), new cc.RotateTo(0.2, this.baseRotation)));
        }
    },

    status: function (code) {
        this.charView.stopAllActions();
        this.stopAllActions();

        this.unselect();

        var dx = cleverapps.styles.KeypadLetterView.wrongAnimation.dx;
        if (code === WordGame.STATUSES.WRONG) {
            this.runAction(
                new cc.Sequence(
                    new cc.MoveBy(0.07, dx, 0),
                    new cc.MoveBy(0.06, -dx, 0),
                    new cc.MoveBy(0.05, dx, 0),
                    new cc.MoveBy(0.04, -dx, 0)
                )
            );
        }

        var delay = 0.22;
        if (cleverapps.config.name === "wordsoup") {
            delay = 0.4;
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.CallFunc(function () {
                this.setScale(1);
                this.setRotation(this.baseRotation);
            }.bind(this))
        ));
    },

    isTouched: function (touch) {
        var point = this.convertTouchToNodeSpace(touch);

        if (cleverapps.config.name === "wordsoup") {
            if (this.letter.selected) {
                return cc.rectContainsPoint(cc.rect(this.width / 4, this.height / 4, this.width * 3 / 4, this.height * 3 / 4), point);
            }
            return cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), point);
        }

        var dir = cc.p(this.x - this.parent.width / 2, this.y - this.parent.height / 2);
        var length = Math.sqrt(dir.x * dir.x + dir.y * dir.y);
        var unitDir = cc.p(dir.x / length, dir.y / length);

        var hitBox = cleverapps.styles.KeypadLetterView.hitBox;
        var center = cc.p(this.width / 2 + unitDir.x * hitBox.offset, this.height / 2 + unitDir.y * hitBox.offset);
        var radius = hitBox.radius;

        return (point.x - center.x) * (point.x - center.x) + (point.y - center.y) * (point.y - center.y) <= radius * radius;
    },

    getShuffleFinishPosition: function () {
        var finishPoint = this.calcPosition(this.letter.index);

        finishPoint = cc.p(
            this.calculateCoordinate(finishPoint.x, "x", this.parent.width),
            this.calculateCoordinate(finishPoint.y, "y", this.parent.height)
        );

        return finishPoint;
    },

    calcPosition: function (index) {
        var alpha = 2 * Math.PI / this.letter.keypad.letters.length;
        var angle = alpha * index;
        var dx = this.radius * Math.sin(angle);
        var dy = this.radius * Math.cos(angle);

        return {
            x: { align: "center", dx: dx },
            y: { align: "center", dy: dy }
        };
    },

    getPathPosition: function () {
        var alpha = 2 * Math.PI / this.letter.keypad.letters.length;
        var angle = alpha * this.letter.index;
        return cc.p(this.parent.width / 2 + this.radius * Math.sin(angle), this.parent.height / 2 + this.radius * Math.cos(angle));
    }
});

cleverapps.styles.KeypadLetterView = {
    shuffleRadius: 100,

    width: 90,
    height: 90,

    wrongAnimation: {
        dx: 10
    },

    hitBox: {
        radius: 60,
        offset: 15
    }
};