/**
 * Created by vladislav on 18.03.2021
 */

var SlotMachineButton = cc.Node.extend({
    ctor: function (slotMachine) {
        this.slotMachine = slotMachine;

        var styles = cleverapps.styles.SlotMachineButton;

        this._super();

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        this.background = new cleverapps.Spine(bundles.slot_machine.jsons.button_json);
        this.addChild(this.background);
        this.background.setPositionRound(this.width / 2, this.height / 2);
        this.background.setAnimation(0, "idle", true);

        this.setScale(0.5);
        this.baseScale = this.scale;
        this.setPositionRound(styles);

        cleverapps.UI.onClick(this, this.onClicked.bind(this));

        this.slotMachine.on("showForce", this.createListener(this.showForce.bind(this)));
    },

    showForce: function () {
        cleverapps.meta.display({
            focus: "SlotMachineForce",
            action: function (f) {
                cleverapps.forces.create(this, Forces.SLOT_MACHINE);
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        });
    },

    onClicked: function () {
        var isForce = false;
        if (cleverapps.forces.isRunningForce()) {
            isForce = true;

            cleverapps.forces.closeRunningForce();
        }

        cleverapps.meta.display({
            focus: "SlotMachine",
            control: "MenuBarGoldItem",
            action: function (f) {
                if (!this.slotMachine.canSpin()) {
                    new SlotMachineBuyWindow();

                    cleverapps.meta.onceNoWindowsListener = f;

                    return;
                }

                this.slotMachine.spin(f, isForce);
                this.runPressAnimation();
            }.bind(this)
        });
    },

    runPressAnimation: function () {
        this.background.setAnimationAndIdleAfter("press", "idle");

        cleverapps.audio.playSound(bundles.slot_machine.urls.button_click_effect);
    }
});

cleverapps.styles.SlotMachineButton = {
    x: { align: "center" },
    y: { align: "center", dy: -210 },

    width: 400,
    height: 250
};