/**
 * Created by vladislav on 15.06.2021
 */

var SlotMachineRulesWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "slotmachineruleswindow",
            title: "SlotMachineRulesWindow.title",
            content: this.getContent()
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.SlotMachineRulesWindow;

        var rewards = [];

        var rows = SlotMachine.PRIZES_TABLE.map(function (prize, index) {
            rewards.push(this.createReward(prize));

            return this.createRow(prize, index);
        }, this);

        var content = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        var rewardsLayout = new cleverapps.Layout(rewards, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.rewardsLayout.margin
        });

        content.addChild(rewardsLayout);
        rewardsLayout.setPositionRound(styles.rewardsLayout);

        return content;
    },

    createRow: function (prize, index) {
        var styles = cleverapps.styles.SlotMachineRulesWindow;

        var icons = [];
        for (var i = 0; i < SlotMachine.REELS_AMOUNT; i++) {
            var icon;
            if (prize.payline[i]) {
                icon = new cc.Sprite(SlotMachine.SYMBOLS[prize.payline[i]].icon);
            } else {
                icon = new cc.Sprite(bundles.slot_machine.frames.point_png);
            }

            cleverapps.UI.fitToBox(icon, {
                width: styles.row.icons.width,
                height: styles.row.icons.height
            });

            var bg = new cc.Scale9Sprite(bundles.slot_machine.frames.reward_bg_png);
            bg.setContentSize2(styles.row.icons.bg);
            bg.addChild(icon);
            icon.setPositionRound(bg.width / 2, bg.height / 2);

            icons.push(bg);
        }

        var iconsLayout = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.row.icons.margin
        });

        var line = this.createLine(prize, index);

        return new cleverapps.Layout([iconsLayout, line], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.row.margin
        });
    },

    createLine: function (prize, index) {
        var styles = cleverapps.styles.SlotMachineRulesWindow;

        var lineNode = new cc.Node();
        lineNode.setAnchorPoint(0.5, 0.5);
        lineNode.setContentSize2(styles.row.reward);

        if (index !== SlotMachine.PRIZES_TABLE.length - 1) {
            var line = new cc.Scale9Sprite(bundles.slot_machine.frames.line_png);
            line.setContentSize2(lineNode.width, line.height);
            lineNode.addChild(line);
            line.setPositionRound(styles.row.line);
        }

        return lineNode;
    },

    createReward: function (prize) {
        var styles = cleverapps.styles.SlotMachineRulesWindow;

        var image;
        var amount;
        if (prize.reward.boosters) {
            image = RewardTypes.boosters.getIcon({ id: Object.keys(prize.reward.boosters)[0] });
            amount = prize.reward.boosters[Object.keys(prize.reward.boosters)[0]];
        } else {
            image = RewardTypes[Object.keys(prize.reward)[0]].getIcon();
            amount = prize.reward[Object.keys(prize.reward)[0]];
        }
        var text = cleverapps.UI.generateOnlyText("=" + amount + "x", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.fitTo(styles.row.reward.text.width);
        var icon = new cc.Sprite(image);
        cleverapps.UI.fitToBox(icon, {
            width: styles.row.reward.icon.width,
            height: styles.row.reward.icon.height
        });

        var rewardNode = new cc.Node();
        rewardNode.setAnchorPoint(0.5, 0.5);
        rewardNode.setContentSize2(styles.row.reward);

        rewardNode.addChild(icon);
        icon.setPositionRound(styles.row.reward.icon);

        rewardNode.addChild(text);
        text.setPositionRound(styles.row.reward.text);

        return rewardNode;
    },

    listBundles: function () {
        return ["slot_machine_rules_window"];
    }
});

cleverapps.styles.SlotMachineRulesWindow = {
    margin: 5,
    row: {
        margin: 10,

        icons: {
            margin: 5,
            width: 80,
            height: 60,
            bg: {
                width: 100,
                height: 80
            }
        },

        reward: {
            width: 230,
            height: 70,

            icon: {
                width: 80,
                height: 60,
                x: { align: "right" },
                y: { align: "center", dy: 5 }
            },

            text: {
                x: { align: "center", dx: -30 },
                y: { align: "center", dy: -5 },

                width: 130
            }
        },

        line: {
            x: { align: "center" },
            y: { align: "bottom", dy: -5 }
        }
    },

    rewardsLayout: {
        margin: 15,

        x: { align: "right" },
        y: { align: "center" }
    }
};