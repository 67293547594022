/**
 * Created by andrey on 01.08.2022
 */

var HideAnimations = function (f) {
    if (this.outcome !== GameBase.OUTCOME_LOST) {
        cleverapps.meta.hideControlsWhileFocused("MenuBarGoldItem");
    }

    f = cleverapps.wait(2, f);
    this.game.keypad.hide(f);
    this.game.board.hide(f);
    if (this.hintView) {
        this.hintView.hideAnimation();
    }
};