/**
 * Created by vladislav on 07.10.2020
 */

var MagnifierView = cc.Node.extend({
    ctor: function (magnifier) {
        this._super();

        this.magnifier = magnifier;

        this.setAnchorPoint(0.5, 0.5);

        this.setupChildren();

        this.animation = new cleverapps.Spine(bundles.game.jsons.hint_booster_json);
        this.setLocalZOrder(100);
        this.addChild(this.animation);

        this.image = new cc.Sprite(bundles.game.frames.loupe_png);
        this.addChild(this.image);
        this.image.setVisible(false);

        this.magnifier.on("use", this.use.bind(this));
        this.magnifier.on("beforeUse", this.beforeUse.bind(this));
        this.magnifier.on("move", this.move.bind(this));
        this.magnifier.on("showUp", this.showUp.bind(this));
    },

    setupChildren: function () {
        var styles = cleverapps.styles.MagnifierView;
        this.setPositionRound(styles);
    },

    showUp: function (silent) {
        if (silent) {
            this.animation.setAnimation(0, "idle", true);
        } else {
            var scene = cleverapps.scenes.getRunningScene();
            var oldParent = this.getParent();
            this.replaceParentSamePlace(scene, {
                keepScale: true
            });

            this.animation.setAnimation(0, "open", false);
            this.animation.setCompleteListenerOnce(function () {
                this.replaceParentSamePlace(oldParent);
                this.setScale(1);
                this.animation.setVisible(false);
                this.image.setVisible(true);
            }.bind(this));

            cleverapps.audio.playSound(bundles.game.urls.hint_booster_before_effect);
        }
    },

    beforeUse: function () {
        var scene = cleverapps.scenes.getRunningScene();

        this.replaceParentSamePlace(scene, {
            keepScale: true
        });
    },

    use: function (hint) {
        if (this.magnifier.ready) {
            this._collect(hint);
        } else {
            this.move();
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.7),
                new cc.CallFunc(this._collect.bind(this, hint))
            ));
        }
    },

    _collect: function (hint) {
        this.image.setVisible(false);
        this.animation.setVisible(true);
        this.animation.setAnimation(0, "activate", false);
        this.animation.setCompleteListenerRemove();

        if (!hint) {
            this.runAction(AnimationsLibrary.coinCloud(this, {
                reward: this.magnifier.getReward().getValue()
            }));
        }
    },

    move: function () {
        var scene = cleverapps.scenes.getRunningScene();

        var position = this.getParent().convertToNodeSpace(scene.convertToWorldSpace(MagnifierView.getCollectPosition()));

        this.image.setVisible(false);
        this.animation.setVisible(true);
        this.animation.setAnimationAndIdleAfter("collect", "collect_idle");

        this.runAction(new cc.MoveTo(0.7, position));
    }
});

MagnifierView.getCollectPosition = function () {
    var styles = cleverapps.styles.MagnifierView;

    var scene = cleverapps.scenes.getRunningScene();

    return cc.p(scene.width / 2, scene.height + styles.collectPosition.offsetY);
};

cleverapps.styles.MagnifierView = {
    x: { align: "right", dx: -22 },
    y: { align: "top", dy: -23 },

    collectPosition: {
        offsetY: -300
    }
};