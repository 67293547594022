/**
 * Created by vladislav on 17.08.2021
 */

var SlotMachineBuyWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.SlotMachineBuyWindow;

        this._super({
            name: "slotmachinebuywindow",
            content: this.createContent(),
            styles: cleverapps.styles.SlotMachineBuyWindow.window,
            button: {
                text: SlotMachine.TICKETS_PRICE + "$$",
                width: styles.button.width,
                height: styles.button.height,
                onPressed: function () {
                    if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.SLOT_MACHINE, SlotMachine.TICKETS_PRICE)) {
                        new RewardWindow({
                            mission: {
                                missionType: Mission.TYPE_SLOT_MACHINE,
                                amount: SlotMachine.TICKETS_BUY_AMOUNT
                            }
                        });

                        this.close();
                    }
                }.bind(this)
            }
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.SlotMachineBuyWindow;

        var bg = new cc.Scale9Sprite(bundles.slot_machine.frames.buy_window_inner_bg_png);
        bg.setContentSize2(styles.bg);

        var amount = this.createAmount();

        var title = this.createTitle();

        var icon = new cc.Sprite(bundles.slot_machine.frames.buy_window_icon_png);

        bg.addChild(amount);
        amount.setPositionRound(styles.amount);

        bg.addChild(title);
        title.setPositionRound(styles.title);

        bg.addChild(icon);
        icon.setPositionRound(styles.icon);

        return bg;
    },

    createTitle: function () {
        var styles = cleverapps.styles.SlotMachineBuyWindow;

        var title = new cc.Scale9Sprite(bundles.slot_machine.frames.buy_window_title_png);
        title.setContentSize2(styles.title);

        var text = cleverapps.UI.generateOnlyText("SlotMachineBuyWindow.title", cleverapps.styles.FONTS.SLOT_MACHINE_BUY_WINDOW_TITLE);
        title.addChild(text);
        text.setPositionRound(styles.title.text);
        text.fitTo(styles.title.text.width);

        return title;
    },

    createAmount: function () {
        var styles = cleverapps.styles.SlotMachineBuyWindow;

        var icon = new cc.Sprite(bundles.slot_machine.frames.ticket_png);
        icon.setScale(0.7);

        var text = cleverapps.UI.generateImageText("x" + SlotMachine.TICKETS_BUY_AMOUNT, cleverapps.styles.FONTS.SLOT_MACHINE_BUY_WINDOW_AMOUNT);

        return new cleverapps.Layout([icon, text], {
            margin: styles.amount.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    getPreferredBundles: function () {
        return ["slot_machine"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SLOT_MACHINE_BUY_WINDOW_AMOUNT: {
        size: 70,
        color: cleverapps.styles.COLORS.WHITE
    },

    SLOT_MACHINE_BUY_WINDOW_TITLE: {
        size: 70,
        color: new cc.Color(254, 233, 1, 255),
        stroke: {
            size: 2,
            color: new cc.Color(143, 68, 56, 255)
        },
        shadow: {
            color: new cc.Color(143, 68, 56, 255),
            direction: cc.size(0, -2),
            blur: 4
        }
    }
});

cleverapps.styles.SlotMachineBuyWindow = {
    margin: 20,

    bg: {
        width: 380,
        height: 600
    },

    amount: {
        margin: 5,

        x: { align: "center" },
        y: { align: "bottom", dy: 25 }
    },

    button: {
        width: 260,
        height: 100
    },

    title: {
        width: 608,
        height: 164,

        text: {
            width: 500,
            x: { align: "center" },
            y: { align: "center", dy: 10 }
        },

        x: { align: "center" },
        y: { align: "center", dy: -68 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "top", dy: 0 }
    },

    window: {
        CloseButton: {
            x: { align: "right" },
            y: { align: "top" }
        }
    }
};