/**
 * Created by vladislav on 13.07.20.
 */

var ShuffleButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster) {
        this._super({
            booster: booster,
            styles: cleverapps.styles.ShuffleButtonBooster
        });

        this.setLocalZOrder(cleverapps.styles.ShuffleButtonBooster.zOrder);
    },

    createIcon: function () {
        return new cc.Sprite(bundles.game.frames.shuffle_png);
    }
});

cleverapps.styles.ShuffleButtonBooster = {
    width: 150,
    height: 120,
    zOrder: 0,

    position: [
        { x: { align: "right", dx: -140 }, y: { align: "bottom", dy: 16 } },
        { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 370 } },
        { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 370 } }
    ],

    phone: {
        scale: 0.8
    },

    content: {
        layout: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        direction: cleverapps.UI.VERTICAL,
        freeBlock: {
            textWidth: 120
        }
    }
};