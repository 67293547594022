/**
 * Created by vladislav on 21.07.20.
 */

cleverapps.BoardView.prototype.animateOpenLetter = function (row, col, options, callback) {
    var letter = this.board.field[row].charAt(col);

    options = options || {};
    callback = callback || function () {};
    if (options.keypadLetter) {
        this.runLetterFlyAnimation(row, col, letter, options.keypadLetter, callback);
        return;
    }

    if (options.type === cleverapps.Board.HINT_LETTER_ANIMATION) {
        var start;
        var indexes = this.board.game.keypad.findWordIndexes(this.board.words[row]);
        start = BaseBlockView.GetView(indexes[col]);

        this.animateHintLetter(row, col, start);
        return;
    }

    if (this.grid.isExistsCell(row, col)) {
        var letterSprite = this.createLetter(letter);
        this.grid.putInCell(row, col, letterSprite, row, cleverapps.Board.LETTER_LAYER);

        this.letters[row + "_" + col] = letterSprite;
    }

    callback();
};

cleverapps.BoardView.prototype.beforeDiscover = function (word) {
    this.letterSprites = [];

    var indexes = this.board.listWordIndexes(word);

    var wordBg = this.wordBg = this.createWordBg(indexes, true);
    var wordBgWidth = wordBg.width;
    var wordBgOriginalPos = wordBg.getPosition();
    wordBg.setAnchorPoint(0, 0.5);
    wordBg.setPositionRound(wordBgOriginalPos.x - wordBgWidth / 2, wordBg.y);
    wordBg.setContentSize2(0, wordBg.height);

    this.letterBgWidth = wordBgWidth / indexes.length;
};

cleverapps.BoardView.prototype.afterDiscover = function (word, silent, callback) {
    callback = callback || function () {};

    var indexes = this.board.listWordIndexes(word);
    var wordBg = this.createWordBg(indexes);

    var oldWordBg = this.wordBg;
    var letterSprites = Array.isArray(this.letterSprites) && this.letterSprites.slice();
    this.letterSprites = [];

    var clearLetterPlaceholders = function (_indexes) {
        _indexes.forEach(function (index) {
            this.grid.getCell(index[0], index[1]).setVisible(false);
            this.letters[index[0] + "_" + index[1]] && this.letters[index[0] + "_" + index[1]].setVisible(false);
        }.bind(this));
    }.bind(this);

    if (silent) {
        this.addWord(word, wordBg);
        oldWordBg && oldWordBg.removeFromParent();

        clearLetterPlaceholders(indexes);

        letterSprites && letterSprites.forEach(function (sprite) {
            sprite.removeFromParent();
        });

        callback();
    } else {
        wordBg.setVisible(false);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                oldWordBg && oldWordBg.removeFromParent();
                wordBg.setVisible(true);

                letterSprites && letterSprites.forEach(function (sprite) {
                    sprite.removeFromParent();
                });

                var text = this.addWord(word, wordBg);
                text.runAction(new cc.Sequence(
                    new cc.ScaleTo(0.2, 1.1),
                    new cc.ScaleTo(0.2, 1)
                ));

                wordBg.runAction(new cc.Sequence(
                    new cc.ScaleTo(0.2, 1.1),
                    new cc.ScaleTo(0.2, 1)
                ));

                clearLetterPlaceholders(indexes);

                cleverapps.audio.playSound(bundles.game.urls.letters_effect);

                callback();
            }.bind(this))
        ));
    }
};

cleverapps.BoardView.prototype.createWordBg = function (indexes, forAnim) {
    var placeholder = new cc.Sprite(bundles.game.frames.placeholder_png);

    var bg = new cc.Scale9Sprite(cleverapps.BoardView.foundWordBg);
    this.grid.addChild(bg);
    bg.setLocalZOrder(1);
    bg.setupChildren = function (init) {
        var minX = this.grid.getCell(indexes[0][0], indexes[0][1]).x;
        var maxX = this.grid.getCell(indexes[indexes.length - 1][0], indexes[indexes.length - 1][1]).x;
        if (forAnim && !init) {
            bg.setPositionRound(minX - this.grid.getCell(indexes[0][0], indexes[0][1]).width / 2, this.grid.getCell(indexes[0][0], indexes[0][1]).y);
        } else {
            bg.setContentSize(maxX - minX + placeholder.width, placeholder.height);
            bg.setPositionRound((maxX + minX) / 2, this.grid.getCell(indexes[0][0], indexes[0][1]).y);
        }
    }.bind(this);
    bg.setupChildren(true);
    bg.setCascadeOpacityEnabled(true);
    return bg;
};

cleverapps.BoardView.prototype.runLetterFlyAnimation = function (row, col, letter, keypadLetter, callback) {
    callback = callback || function () {};
    var styles = cleverapps.styles.BoardView.discoverAnimation;

    cleverapps.audio.playSound(bundles.game.urls.open_word_effect);

    var letterFlyAnimation = function () {
        var letter = BaseBlockView.GetView(keypadLetter);

        letter.stopAllActions();

        letter.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this), {
            keepScale: true
        });

        letter.setLocalZOrder(letter.getLocalZOrder() + 100);

        if (!letter || !letter.isRunning() || !letter.getParent()) {
            cleverapps.throwAsync("runLetterFlyAnimation letter error");
            return;
        }

        var placeholder = this.grid.getCell(row, col);
        var pos = placeholder.getPosition();
        pos.y += styles.letter.offsetY;
        var target = letter.getParent().convertToNodeSpace(placeholder.getParent().convertToWorldSpace(pos));

        var targetScale = letter.adjustScaleTo(placeholder);
        targetScale.x = letter.getScaleX() / targetScale.x;
        targetScale.y = letter.getScaleY() / targetScale.y;

        var first = letter.getPosition();
        var third = target;
        var second = cc.p((first.x + 2 * third.x) / 3, (first.y + 2 * third.y) / 3 - styles.bezier.y);

        letter.runAction(new cc.Sequence(
            new cc.DelayTime(col * 0.05),
            new cc.Spawn(
                new cc.BezierTo(0.5, [first, second, third]),
                new cc.ScaleTo(0.5, targetScale.x, targetScale.y)
            ),
            new cc.RemoveSelf(),
            new cc.CallFunc(callback)
        ));
    }.bind(this);

    var placeholderAnimation = function () {
        var placeholder = this.grid.getCell(row, col);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.4 + col * 0.05),
            new cc.CallFunc(function () {
                if (this.letters[row + "_" + col]) {
                    this.letters[row + "_" + col].runAction(new cc.FadeOut(0.1));
                }
                placeholder.runAction(new cc.FadeOut(0.1));
            }.bind(this)),
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.collectMissionReward(row, col);
                this.letters[row + "_" + col] && this.letters[row + "_" + col].setVisible(false);
                placeholder.setVisible(false);
            }.bind(this))
        ));
    }.bind(this);

    var spriteAnimation = function () {
        var sprite = this.createLetter(letter);
        this.letterSprites.push(sprite);
        this.grid.addChild(sprite);

        sprite.visible = false;
        var placeholder = this.grid.getCell(row, col);
        sprite.runAction(new cc.Sequence(
            new cc.DelayTime(0.5 + col * 0.05),
            new cc.CallFunc(function () {
                this.wordBg.setContentSize2((col + 1) * this.letterBgWidth, this.wordBg.height);
                sprite.setPositionRound(placeholder.x, placeholder.y + styles.sprite.offsetY);
                sprite.setLocalZOrder(2);
                sprite.visible = true;
            }.bind(this))
        ));
    }.bind(this);

    letterFlyAnimation();
    placeholderAnimation();
    spriteAnimation();
};

cleverapps.BoardView.prototype.addWord = function (word, parent) {
    var text = cleverapps.UI.generateOnlyText(word.toUpperCase(), cleverapps.styles.FONTS.BOARD_TEXT);
    if (!this.wordsBg) {
        this.wordsBg = [];
    }
    this.wordsBg.push(parent);
    this.grid.addChild(text);
    text.setLocalZOrder(1);
    text.setupChildren = function () {
        var styles = cleverapps.styles.BoardView.discoverAnimation;
        text.setFontSize(cleverapps.styles.FONTS.BOARD_TEXT.size);
        text.fitTo(undefined, styles.text.height);
        var position = text.getRelativeCoordinates(parent);
        text.setPositionRound(position.x, position.y + styles.text.offsetY * this.grid.getScale());
    }.bind(this);
    text.setupChildren();

    return text;
};

cleverapps.BoardView.prototype.showUpAnimation = function (callback, silent) {
    if (silent) {
        this.setVisible(true);
        this.showUpBackground(true);
        callback();
    } else {
        this.setVisible(true);
        this.setOpacity(0);
        this.runAction(new cc.Spawn(
            new cc.CallFunc(function () {
                regularShowUp(this.grid.listCells(), 1.0);
                this.showUpBackground(false);
            }.bind(this)),
            new cc.FadeIn(0.25),
            new cc.Sequence(
                new cc.DelayTime(1.2),
                new cc.CallFunc(function () {
                    callback();
                })
            )
        ));
    }
};

cleverapps.BoardView.prototype.elementsHide = function () {
    var views = Object.values(this.stars)
        .concat(Object.values(this.grid.listCells()))
        .concat(Object.values(this.letters))
        .concat(this.wordsBg)
        .concat([this.grid]);

    views.filter(Boolean).forEach(function (view) {
        if (view.highlightAnimation) {
            view.setOpacity(255);
            view.highlightAnimation.removeFromParent();
        }
        view.runAction(new cc.Spawn(
            new cc.FadeOut(0.5)
        ));
    });
};

cleverapps.BoardView.prototype.hideAnimation = function (f) {
    this.setCascadeOpacityEnabled(true);
    this.grid.setCascadeOpacityEnabled(true);

    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.5),
        new cc.CallFunc(this.elementsHide.bind(this)),
        new cc.FadeOut(0.5),
        new cc.CallFunc(function () {
            this.setVisible(false);
            f();
        }.bind(this))
    ));
};

cleverapps.BoardView.prototype.showUpBackground = function (silent) {
    if (!this.boardBg) {
        return;
    }
    if (silent) {
        this.boardBg.setOpacity(255);
        return;
    }

    this.boardBg.setOpacity(0);
    this.boardBg.runAction(new cc.FadeIn(0.25));
};

cleverapps.BoardView.prototype.animateLastWord = function (indexes) {
    var animateLine = function (actionIndex, maxCountActions) {
        if (!Game.currentGame || Game.currentGame.outcome !== undefined || this.board.isComplete()) {
            return;
        }

        if (!maxCountActions) {
            maxCountActions = 1;
        }

        if (actionIndex === 0) {
            cleverapps.audio.playSound(bundles.game.urls.last_word_effect);
        }

        indexes.forEach(function (pos, index) {
            var row = pos[0];
            var col = pos[1];

            var frame = new cc.Sprite(bundles.game.frames.board_letter_frame_png);
            this.grid.addChild(frame);
            frame.setPositionRound(this.grid.getCell(row, col).getPosition());
            frame.setOpacity(0);
            frame.setLocalZOrder(3);
            frame.runAction(new cc.Sequence(
                new cc.DelayTime(0.2 * index),
                new cc.CallFunc(function () {
                    if (this.board.isComplete()) {
                        frame.stopAllActions();
                    }
                }.bind(this)),
                new cc.FadeIn(0.2),
                new cc.FadeOut(0.2),
                new cc.CallFunc(function () {
                    if (index === indexes.length - 1) {
                        if (actionIndex === Math.max(maxCountActions - 1, 0)) {
                            this.runAction(new cc.Sequence(
                                new cc.DelayTime(10.0),
                                new cc.CallFunc(function () {
                                    animateLine(0, maxCountActions);
                                })
                            ));
                        } else {
                            animateLine(actionIndex + 1, maxCountActions);
                        }
                    }
                }.bind(this)),
                new cc.RemoveSelf()
            ));
        }, this);
    }.bind(this);

    this.runAction(
        new cc.Sequence(
            new cc.DelayTime(2.0),
            new cc.CallFunc(function () {
                animateLine(0);
            })
        )
    );
};

cleverapps.overrideStyles(cleverapps.styles.BoardView, {
    minSpaceX: 120,
    maxSpaceX: 200,

    Letter: {
        offset: 4,
        paddingBottom: 4
    },
    Coin: {
        paddingBottom: 6
    },
    Background: {
        width: "grid"
    },
    Star: {
        scale: 0.75,
        collect: {
            height: 250
        }
    },

    discoverAnimation: {
        letter: {
            paddingY: -10,
            offsetY: 0
        },
        sprite: {
            offsetY: 0
        },
        text: {
            offsetY: 2,
            height: 125
        },
        bezier: {
            y: 400
        },
        margin: -40
    }
});