/**
 * Created by vladislav on 07.07.20.
 */

var Scramble = function () {
    WordGame.apply(this, arguments);

    this.counter.registerStage(0, this.keypad.fall.bind(this.keypad));
    this.counter.registerStage(5, CoinBlock.process);
    this.counter.registerStage(10, this.keypad.slide.bind(this.keypad));
    this.counter.registerStage(20, this.shuffle.bind(this));
    this.counter.registerStage(25, this.keypad.collectLeftMagnifiers.bind(this.keypad));
    this.counter.registerStage(30, this.keypad.processMagnifiers.bind(this.keypad));
    this.counter.registerStage(40, this.addPendingBonusBlocks.bind(this));
    this.counter.registerStage(50, this.updateCurrentHighlighter.bind(this));
    this.counter.registerStage(60, this.keypadTutorial.bind(this));
};

var Game = Scramble;

Scramble.prototype = Object.create(WordGame.prototype);
Scramble.prototype.constructor = Scramble;

Scramble.prototype.showScreen = function (f, silent) {
    cleverapps.meta.compound(f, [
        function (f) {
            var hint = cleverapps.scenes.getRunningScene().hintView;
            if (hint) {
                hint.showUp(f, silent);
            } else {
                f();
            }
        },

        function (f) {
            this.board.showUp(f, silent);
        }.bind(this),

        function (f) {
            this.keypad.showUp(f, silent);
        }.bind(this)
    ]);
};

Scramble.prototype.calculatePendingBonusBlocks = function () {
    if (cleverapps.flags.bonusBlocksDisabled) {
        return 0;
    }

    if (this.level.isRegular() && !this.level.isCurrentLevel()) {
        return 0;
    }

    if (this.keypad.words.length === 0) {
        return 0;
    }

    var openedWords = this.board.words.length - this.keypad.words.length;
    var amount = 0;

    if (this.level.isBonusRound() || this.level.isBonus()) {
        return Math.floor(Math.random() * 3) + 1;
    }

    if (openedWords === 1) {
        amount = 1;
    } else if (openedWords % 5 === 0 && openedWords > 0) {
        if (openedWords / 5 === 1) {
            amount = 2;
        } else {
            amount = 3;
        }
    }

    return amount;
};

Scramble.prototype.addPendingBonusBlocks = function () {
    if (this.pendingBonusBlocks > 0) {
        var delay = this.level.isBonusRound() || this.level.isBonus() ? 20 : 100;
        for (var i = 0; i < this.pendingBonusBlocks; i++) {
            this.counter.setTimeout(function () {
                this.keypad.addRandomBonus();
            }.bind(this), i * delay + Math.random() * delay);
        }

        this.pendingBonusBlocks = 0;
    }
};

Scramble.prototype.updateCurrentHighlighter = function () {
    this.currentHighlighter.update();
};

Scramble.prototype.shuffle = function () {
    if (!this.keypad.moveExists() && this.keypad.words.length > 0) {
        this.keypad.shuffle();
    }
};

Scramble.prototype.keypadTutorial = function () {
    this.keypad.showTutorialStep();
};

Scramble.prototype.getInfo = function () {
    var info = WordGame.prototype.getInfo.call(this);
    info.hintBoosters = this.keypad.getMagnifiers();
    info.highlightBooster = this.currentHighlighter.isActive();
    info.keypad = this.keypad.getState();
    info.easy = this.keypad.easy;
    return info;
};