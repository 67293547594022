/**
 * Created by vladislav on 18.03.2021
 */

var SlotMachineMissionLogic = function (mission) {
    this.mission = mission;

    this.slotMachine = new SlotMachine(mission.result);

    this.slotMachine.onChangeTickets = function (silent) {
        var value = this.getTickets() - mission.result;
        mission.update(value, undefined, silent);
    }.bind(this);
};

SlotMachineMissionLogic.prototype.processEvent = function (options) {
    this.mission.update(options.amount);
};

SlotMachineMissionLogic.prototype.getTickets = function () {
    return this.slotMachine.getTickets();
};

SlotMachineMissionLogic.prototype.isForceAvailable = function () {
    return this.slotMachine.isForceAvailable();
};

SlotMachineMissionLogic.prototype.isHelpForceAvailable = function () {
    return this.slotMachine.isHelpForceAvailable();
};

SlotMachineMissionLogic.prototype.showForce = function () {
    return this.slotMachine.showForce();
};

SlotMachineMissionLogic.prototype.showHelpForce = function () {
    return this.slotMachine.showHelpForce();
};

SlotMachineMissionLogic.prototype.getTargets = function () {
    return ["slotMachineTicket"];
};

SlotMachineMissionLogic.prototype.isAttentionRequired = function () {
    return this.getTickets() > 0;
};